.order-track-new .order-detail-left {
  position: fixed;
  left: 0;
  z-index: 999;
  background: #fff;
  overflow-y: auto;
  width: 40%;
  top: 0;
}

.top-down-arrow {
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.gm-style-iw-chr,
.gm-style-iw-tc {
  display: none;
}

.info-window-track {
  max-width: 200px;
}

.info-window-track .picking-track-address {
  color: #555558;
}
.restaurnt-img-track figure {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 11px;
}

.progress-bar-track {
  background: #0062ff;
  width: 100%;
  height: 7px;
  border-radius: 10px;
  margin-bottom: 33px;
}

.restaurnt-img-track figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.order-track-new .address-track-new {
  margin-bottom: 17px;
  height: auto;
}

.order-track-new .address-track-new:first-child::before {
  content: "";
  background: #fff;
  width: 44px;
  height: 36px;
  position: absolute;
  top: 0;
}

.order-track-new .address-track-new:last-child {
  margin-bottom: 0px;
}

.order-track-new
  .address-track-new:last-child
  .address-left-wrapper
  .address-left-wrapper {
  width: calc(100% - 40px);
}

.order-track-new .oder-contant p img {
  margin-right: 10px;
}

.order-track-new .address-icon {
  margin-top: -23px;
  position: relative;
  z-index: 5;
}

.order-track-new .address-track-new:last-child::after {
  content: "";
  background: #fff;
  width: 26px;
  height: 55px;
  position: absolute;
  bottom: 11px;
  left: 6px;
}

.order-track-new .address-left-wrapper {
  width: calc(100% - 40px);
}

.order-track-new .order-detail-right {
  margin-left: auto;
  width: 60%;
}

.order-track-outer {
  overflow: hidden;
}

.order-track-outer::before {
  color: #92929d;
  background-image: repeating-linear-gradient(
    0deg,
    #92929d,
    #92929d 5px,
    transparent 0,
    transparent 8px
  );
  width: 1px;
  position: absolute;
  bottom: 50px;
  left: 19px;
  z-index: -1;
  content: "";
  position: absolute;
  overflow: hidden;
  height: 100%;
}

@media (max-width: 991px) {
  .order-track-new .order-detail-left {
    width: 50%;
  }

  .order-track-new .order-detail-right {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .main-container.main-container-new {
    height: 100vh !important;
    overflow-y: hidden;
    padding-bottom: 0px;
  }

  .order-track-new .badge-one {
    margin-top: 8px;
  }

  .order-track-new .order-detail-left,
  .order-track-new .order-detail-right {
    height: 100vh !important;
  }

  .order-track-new .oder-contant {
    margin: 33px 24px;
  }

  .order-track-new .oder-contant p {
    font-size: 18px;
  }
}

@media (max-width: 575.98px) {
  .order-track-new .order-detail-left {
    left: 50%;
    transform: translateX(-50%);
    padding-left: 0px;
    top: 76%;
    transition: 0.5s;
    z-index: 99;
    padding-bottom: 64px;
    width: 96%;
    box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11),
      0 -1px 0 rgba(38, 89, 115, 0.05);
    border-radius: 7px;
  }

  .progress-bar-track {
    margin-bottom: 17px;
  }

  .order-detail-left.showPanel {
    top: 10%;
  }

  .order-track-outer::before {
    left: 15px;
  }

  .order-track-new .address-track-new {
    margin-bottom: 9px;
    height: auto;
  }

  .order-track-new .address-track-new:first-child::before {
    width: 30px;
    height: 36px;
  }

  .order-track-new .address-icon {
    width: 30px;
  }

  .order-track-new .driver-info {
    padding: 0rem 0px !important;
  }

  .order-track-new .driver-font {
    font-size: 15px !important;
  }

  .order-track-new .order-head img {
    margin: 0 auto;
    display: block;
    max-width: 106px;
  }

  .order-track-new .custom-driver-box {
    height: 37px;
    width: 37px;
    border-radius: 11px;
  }

  .order-track-new .custom-driver-box svg {
    width: 18px;
  }

  .order-content-new {
    font-size: 16px !important;
    margin-bottom: 0px;
  }

  .order-track-new .oder-contant {
    margin: 14px 12px;
  }

  .order-track-new .order-text {
    font-size: 14px !important;
  }

  .order-track-new .order-lebal {
    font-size: 14px !important;
  }

  .order-track-new .oder-contant p img {
    margin-right: 10px;
    height: 15px;
  }

  .order-track-new .badge-one {
    margin-top: 0px;
    font-size: 15px !important;
    margin-bottom: 24px;
  }
}
