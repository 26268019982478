:root {
  --defaultWhite: #fff;
  --defaultBlack: #000;
  --btnColor: #f06f00;
  --commonborder: #f06f00;
}

html {
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

.btn:focus-visible {
  box-shadow: none;
  background: var(--btnColor);
  border-color: var(--btnColor);
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background: rgb(240, 111, 0);
  border-color: rgb(240, 111, 0);
}
.btn:active {
  background: transparent !important;
  border-color: var(--commonborder) !important;
}
.common-btn:active {
  color: #000 !important;
}
.banner-input-sec button:active {
  color: #000 !important;
}
body {
  font-family: "Lato";
  color: var(--defaultBlack);
  font-size: 14px;
  list-style: 24px;
}

ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
a {
  text-decoration: none;
  transition: 0.4s;
}
.common-btn:hover {
  background: transparent;
  border-color: var(--commonborder);
  color: var(--btnColor);
}
.banner-input-sec .search-btn:active {
  background-color: var(--btnColor);
  border-color: var(--commonborder);
}
.common-heading p a {
  color: var(--btnColor);
}

.theme-btn {
  color: var(--btnColor);
}
.common-btn {
  background: var(--btnColor);
  color: var(--defaultWhite);
  padding: 10px 17px 10px 17px;
  border-radius: 8px;
  font-size: 17px;
  border-color: var(--commonborder);
  font-weight: 700;
}
.btn.cancel_btn,
.btn.cancel_btn:active,
.swal2-styled.swal2-cancel,
.swal2-styled.swal2-cancel:active {
  background: #fff !important;
  border: 1px solid #333 !important;
  color: #333 !important;
  font-weight: 500 !important;
}

.newsletter-sec .form-control {
  padding-right: 136px;
}
.form-control {
  background-color: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  padding: 15px 21px;
  height: 56px;
  border-color: #00000026;
}
.form-control::placeholder {
  font-size: 14px;
  color: #000;
  opacity: 0.4;
}

.form-control:focus {
  border-color: var(--btnColor);
  outline: 0;
  box-shadow: none;
}

.common-heading h2 {
  font-weight: 700;
  font-size: 39px;
}

.react-tel-input .selected-flag {
  width: 51px;
  height: 100%;
  padding: 0 0 0 14px;
  background-color: #fff;
}

.react-tel-input .form-control {
  border-radius: 8px;
  border-color: 1px solid #f2f2f2;
}
/* ====**header**===== */

.header-login-btn a {
  padding: 9px 22px 12px 22px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 17px;
  color: #fff;
  /* border: 1px solid #fff; */
  /* color: var(--defaultBlack); */
}

.fixed-top .sign-in-btn {
  border-color: #000 !important;
}
.header-login-btn a:last-child {
  margin-right: 0px;
}
header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 15px 0px;
  z-index: 15;
  transition: all 0.3s ease-in-out;
}

header.fixed-top {
  width: 100%;
  z-index: 15;
  /* z-index: 9999; */
  top: 0%;
  -webkit-animation: slide-down 0.7s;
  animation: slide-down 0.7s;
  -webkit-box-shadow: 0px 0.071rem 0.71rem 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0.071rem 0.71rem 0px rgba(0, 0, 0, 0.2);
  background: #fff;
}

@keyframes slide-down {
  0% {
    opacity: 1;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/* ====**header**===== */

.toast-success {
  background: var(--btnColor) !important;
  color: #ffffff !important;
}
.toast-error {
  background: #ff2400 !important;
  /* background: var(--btnColor) !important; */
  color: #ffffff !important;
}
.Toastify__close-button > svg {
  fill: #ffffff !important;
}

/* main-container */

.main-container {
  /* margin-top: 130px; */
  margin-top: 77px;
}

.form-check-input:checked {
  background-color: var(--btnColor);
  border-color: var(--commonborder);
}
.form-check-input:focus {
  box-shadow: none;
  border-color: var(--commonborder);
}
.form-check {
  font-size: 16px;
}
.form-control.dropdown .dropdown-toggle {
  background: transparent;
  border: none;
  color: #000;
  width: 100%;
  text-align: start;
  padding: 0px;
}
.form-control.dropdown .show {
  transform: translate(0px, 55px) !important;
  width: 100%;
  max-height: 258px;
  overflow-y: auto;
  margin-top: 10px;
}
.dropdown-item {
  border-bottom: 1px solid #dbdcdd;
  padding: 10px 15px;
  font-size: 16px;
}

.dropdown-item:active {
  background-color: var(--btnColor);
}

.dropdown-toggle::after {
  float: right;
  margin-top: 10px;
}
.react-tel-input .flag-dropdown {
  border-right: 1px solid #cacaca !important;
  margin: 3px;
  border: none;
}
.error-message {
  margin-bottom: 0px;
  padding-bottom: 0px !important;
}
.default-back {
  height: 500px;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #1c1b1a17;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.back-btn {
  cursor: pointer;
  margin-left: 5px;
}
.back-btn:hover {
  color: var(--btnColor);
}

.success-check > div {
  width: 250px !important;
  height: 250px !important;
}
.circle-process {
  color: #f06f00 !important;
  margin-left: 10px;
  position: absolute;
  top: 15px;
  right: 33px;
  background: #fff;
}

/* .btn:active{
    background-color: var(--btnColor)!important;
    border-color: var(--commonborder)!important;
} */
.login-sec.forgot-sec {
  margin-top: 126px;
}
.main-logo .btn:active {
  background-color: var(--defaultWhite) !important;
  border-color: var(--defaultWhite) !important;
}
/* main-container */

.autocomplete-dropdown-container {
  width: 100%;
  position: relative;
  text-align: left;
  z-index: 2;
  border: 1px solid #0003;
  border-top: 0px;
}
.header-autocomplete-dropdown ~ .autocomplete-dropdown-container {
  position: absolute !important;
}
.autocomplete-dropdown-container span {
  font-size: 16px;
  padding: 14px 13px;
  display: block;
  border-bottom: 1px solid #efefef;
}

/* modal */

.modal-content {
  border-radius: 8px;
  overflow: hidden;
}

.modal-dialog {
  max-width: 676px;
}

.modal-header .modal-title {
  font-size: 24px;
  font-weight: 700;
}

.modal-header .modal-title span {
  display: block;
}

.modal-header span {
  display: block;
  font-size: 17px;
}

.modal-header {
  flex-direction: column;
  align-items: start;
  padding: 27px 40px;
}

.modal-header .btn-close {
  position: absolute;
  right: 37px;
  top: 34px;
}
.modal-dialog {
  height: 100%;
  display: flex;
  align-items: center;
}

.modal-body {
  padding: 0px 40px 29px;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: var(--btnColor) !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #000;
}
/* modal */

/* myaccount-sec */

.my-account-body span {
  display: block;
  font-size: 16px;
  margin-bottom: 4px;
}

.my-account-body strong {
  font-size: 21px;
}

.my-account-body .detail {
  margin-bottom: 13px;
}

/* myaccount-sec */

.mobile-arrow-btn button {
  position: absolute;
  left: 11px;
  top: 25px;
  background: transparent;
  border: none;
  color: #000;
}

.signup-subscribe .form-check-input:checked ~ label {
  color: var(--btnColor);
}

.body-outer {
  position: fixed;
  background: transparent;
  top: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
.modal-dialog {
  height: auto;
}

.schedule_asap:disabled {
  background-color: lightgray !important;
  border-color: lightgray !important;
  color: black;
}

/* saved-addresss */

.addressLists li {
  border-bottom: 1px solid hsla(0, 0%, 59%, 0.33);
  padding: 12px 19px 12px 20px;
  text-align: left;
  cursor: pointer;
  font-family: "Inter";
  font-size: 13px;
  position: relative;
}
.addressLists li.active {
  color: var(--btnColor);
}
.saved-address-modal p {
  margin-bottom: 8px;
  font-family: "Inter";
  margin-top: 12px;
  text-align: left;
  margin-bottom: 8px;
  font-weight: bold;
}

.addressLists {
  max-height: 250px;
  overflow: auto;
}

.picktime-mobile-picktime.address_picker_main {
  overflow: initial;
  max-height: initial;
}

.address_picker_main .autocomplete-dropdown-container {
  max-height: 250px;
  overflow-y: auto;
}

/* saved-addresss */

.close-restra {
  background-color: #00000094;
  color: #fff;
  z-index: 1;
}

.common-gift-btn button {
  min-width: 265px;
  max-width: 265px;
  padding: 13px 10px;
  margin-right: 10px;
}
.common-gift-btn {
  display: flex;
}
/* egift-progressbar */

.add-gift-amount .track_circle {
  background: #fff;
  border-color: #000;
  box-shadow: none;
}

.add-gift-amount.track_detail ul::before,
.add-gift-amount.track_detail ul li:first-child:before,
.add-gift-amount.track_detail ul li:last-child:after {
  border-color: #000;
  top: 34%;
  transform: translate(0);
}
.add-gift-amount .progressBar li strong {
  font-weight: 500;
  font-size: 15px;
}

.add-gift-amount .success-booking .track_circle {
  background: var(--btnColor);
  border: none;
}
.add-gift-amount .progressBar li {
  cursor: pointer;
  flex-grow: 0;
}
.add-gift-amount {
  max-width: 622px;
}
.add-gift-amount.track_detail ul {
  max-width: 450px;
  margin: 0;
  box-shadow: none;
}

/* egift-progressbar */

/* balance-gift */

.balance-card .form-check {
  position: relative;
  width: 20%;
  margin: 0;
  padding: 0;
}

.balance-card label {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 11px 9px;
  border-right: 1px solid #000;
  cursor: pointer;
  font-family: "Inter";
}
.balance-card label .MuiRadio-root {
  opacity: 0;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
}

.balance-card label .MuiFormControlLabel-label {
  width: 100%;
  border-right: 1px solid #000;
}

.balance-card label .MuiRadio-root input:checked span {
  background: #000;
}

.balance-card {
  border: 1px solid #000;
  border-radius: 6px;
  position: relative;
}

.balance-card .form-check .form-check-input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  margin: 0;
}

.balance-card .form-check input:checked ~ label {
  background: #000;
  color: #fff;
}

/* balance-gift */

.common-btn.disabled {
  background-color: var(--btnColor);
  opacity: 0.5;
  border-color: var(--commonborder);
}

.common-btn.disabled:hover {
  color: #fff;
}

.modal-header .btn-close:focus {
  box-shadow: none;
}

/* order-detail */

.order-item-details > div {
  padding: 11px 0px;
  line-height: 29px;
}

.order-item-details > div strong {
  font-size: 18px;
}
.order-item-details > div span {
  font-size: 16px !important;
}

/* order-detail */

.footer-menu li a:hover {
  color: var(--btnColor) !important;
  transform: translateX(5px);
}
.footer-menu li a {
  display: block;
}

/* braintree-styles */

.braintree-options-list {
  grid-gap: 10px;
  background: #fff;
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  padding-top: 5px;
}
.braintree-toggle,
.braintree-options-list {
  margin-bottom: 20px;
}
.braintree-options-list .braintree-option {
  align-items: center;
  background: #fff;
  background-color: #fff;
  border: 1px solid #cacaca;
  border-radius: 2.5px;
  height: 38px;
  justify-content: center;
  width: 68px !important;
}
.braintree-options-list .braintree-option .braintree-option__label {
  display: none;
}
/* braintree-styles */

.dropdown-item:hover {
  background: #d8d8d8;
}

.congrats-inner {
  margin-top: -45px;
}
.cancel-order-img img {
  width: 100px;
  height: 100px;
}

/* 22/3/23 */

/* .rest-list-modal .modal-body .add-cart-inner-modal {
  max-height: 500px;
  overflow-y: auto;
  padding: 0px 8px 0px 5px;
} */
/* 22/3/23 */

.menulisting__list .activeCategoryLink {
  color: #1c1b1a;
  background: #ffe4cd;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  text-decoration: none;
  border-radius: 8px;
}

.filter-city ul li {
  font-size: 14px;
  white-space: nowrap !important;
  justify-content: center;
}
.meet_at_door {
  float: none;
  border-radius: 5px !important;
  /* position: absolute; */
  top: 0;
  right: 0;
  font-weight: bold;
  background-color: #ebebeb;
  padding: 8px 10px;
  display: block;
  clear: both;
  width: 120px;
  text-align: center;
}

.arror_crose {
  padding: 11px;
}

.search-reasturants .search-icon {
  display: none;
}

/* .sidenav.open button {
    position: absolute;
    right: 0;
    top: 5px;
} */

/* .sidenav.open > ul {
    padding-top: 32px;
} */

.triggers {
  padding: 1rem;
}
.triggers ul {
  display: flex;
  gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
}
.triggers button {
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
  padding: 0;
}
.triggers button.active {
  color: green;
}

.sections {
  height: 300px;
  overflow: auto;
}
.sections section {
  padding: 1rem;
}
.sections section:nth-child(odd) {
  background-color: #f0f0f0;
}

.filter-city .slick-track {
  margin-left: 0;
}

.map_space {
  padding-left: 80px;
}

.password_wrap .form-control {
  padding-right: 46px;
}

.store_tab a img {
  max-height: 54px;
}

.header-notificatin-wrapper.minicart_header .common-btn {
  margin-top: 10px;
}

.header-notificatin-wrapper.minicart_header {
  top: 77px !important;
  width: 370px !important;
}

.header-notificatin-wrapper.minicart_header .common-btn {
  margin-top: 10px;
}

.feature-slider .slick-prev {
  left: -15px;
  width: 36px;
  height: 36px;
}

.feature-slider .slick-next {
  right: -15px;
  width: 36px;
  height: 36px;
}

.mobile_search_ss .cross-icon {
  right: 15px;
  z-index: 2;
  bottom: -6px;
}

.picktime-mobile-picktime {
  /* max-height: 480px; */
  overflow: auto;
}

.progressBar.gift_card_progress_bar li:after,
.progressBar.gift_card_progress_bar li:before {
  display: none;
}

.common-reward-sec form .form-select option {
  background: #fff;
}

.common-reward-sec form .form-select option:active,
.common-reward-sec form .form-select option:hover,
.common-reward-sec form .form-select option:focus {
  background: #f06f00 !important;
  color: #fff !important;
}

.progressBar.gift_card_progress_bar li {
  flex: 1;
  position: relative;
}

.progressBar.gift_card_progress_bar li:before {
  content: "";
  position: absolute;
  left: 0;
  height: 2px;
  background: #000 !important;
  top: 20px;
  display: block;
}

.progressBar.gift_card_progress_bar {
  justify-content: flex-start !important;
  max-width: 100% !important;
}

.delivery-info .pickup-span {
  padding-left: 0px !important;
}

.view-cart-wrapper h2,
.view-cart-wrapper strong {
  width: 30px;
}

.view-cart-wrapper strong {
  text-align: right;
}

.section_space {
  padding: 40px 0;
}
.newsletter-sec .btn.disabled,
.newsletter-sec .btn:disabled,
.newsletter-sec fieldset:disabled .newsletter-sec .btn {
  background: var(--btnColor);
  border-color: var(--commonborder);
  opacity: 1;
}

.cancel-order-status .btn {
  width: 220px;
}

/* .order-page-sec .default-back {
    height: 100%;
} */

.btn-loader {
  flex: 0 0 30px;
}
.filter-services .filter_btn_active {
  background: var(--btnColor);
  border-color: var(--btnColor);
}

.filter-services .filter_btn_active img {
  filter: brightness(1);
}

.items_order_detail li {
  padding: 17px 10px;
  margin-bottom: 10px;
}

.items_order_detail li .remove-item {
  margin-right: 0;
}

.paid-by {
  margin-top: 24px;
  background: #eee;
  padding: 11px;
  border-radius: 5px;
}

.paid-by {
  margin-top: 24px;
  background: #f9f9f9;
  padding: 11px;
  border-radius: 5px;
}

.order-detail-instruct > ul > li:last-child {
  font-weight: bold;
  padding-top: 6px;
}

/* .page_loader {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
} */
.page-loading-new {
  width: 100%;
}

.lazy-page-loading-new {
  width: 100%;
  display: block;
  padding: 200px;
}

.widthtwinty {
  width: 20px !important ;
  height: 20px !important;
  flex: 0 0 20px;
}

.widththirty {
  width: 30px !important ;
  height: 30px !important;
  flex: 0 0 30px;
}

.widthtwintyFive {
  width: 25px !important ;
  height: 25px !important;
  flex: 0 0 25px;
}

.restaurant-cart-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 90px !important;
  right: 20px;
  background: #f06f00;
  width: 50px;
  height: 50px;
  text-decoration: none;
  border-radius: 35px;
  transition: all 0.3s ease;
  color: #fff;
  z-index: 999;
  cursor: pointer;
}

.dev-tooltip .dev-tooltiptext {
  right: 0;
  left: auto;
  transform: translateX(60%);
}

.add_food_dudes_action .btn {
  font-size: 16px !important;
}
/* .scroller-fixing{opacity:0;} */

.order-service-fees span {
  /* font-weight: 600; */
  font-weight: 400;
  border-right: 1px solid #ddd;
  display: block;
  color: #666;
  padding-right: 10px;
}
.order-service-fees .service_text {
  border-right: none;
  padding-right: 0;
  margin-left: 10px;
}
.order-service-fees {
  margin-bottom: 6px;
}
.rest_location {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #666;
  margin-bottom: 8px;
}

.rest_location img {
  flex: 0 0 12px;
  margin-right: 6px;
  width: 12px;
}

.rest_location small {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delivery_time {
  position: absolute;
  /* z-index: 999; */
  z-index: 14;
  background: #ffff;
  padding: 8px 19px;
  top: 14px;
  left: 91px;
  box-shadow: rgb(0 0 0 / 19%) 0px 0.071rem 0.71rem 0px;
  border-radius: 8px;
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.main-container.ticker-visible {
  margin-top: 97px;
}

.rest-detail.ticker-visible {
  margin: 98px 0px 0px;
}

.qty-wrap {
  margin-right: 6px;
}
.qty-disable {
  color: lightgray !important;
}
.qty-button-disable {
  color: lightgray !important;
  cursor: not-allowed !important;
}
.qty-wrap .qty-count {
  padding: 8px 10px;
  border-radius: 6px;
  display: inline-block;
  background: #f5f5f5;
  width: 64px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  margin: 0 5px;
}

.qty-wrap button {
  background: transparent;
  border: none;
  color: #565656;
  padding: 0;
  width: 32px;
  padding: 5px;
}

.qty-wrap {
  display: flex;
  align-items: center;
  width: 124px;
}

.qty-wrap button svg {
  margin: 0;
}

/* new-css */

.header-section.only-home .main-logo img {
  filter: brightness(0) invert(1);
}

.header-section.only-home.fixed-top .main-logo img {
  filter: none !important;
}
.css-ttwr4n {
  display: none !important;
}

.header-login-btn a:last-child {
  margin-right: 0px !important;
  background: #fff !important;
  color: var(--btnColor) !important;
  /* color: #000 !important; */
}

.fixed-top .header-login-btn a {
  /* color: var(--btnColor); */
  color: #000;
  border-color: var(--btnColor);
}

.fixed-top .header-login-btn a:last-child {
  background: var(--btnColor) !important;
  color: var(--defaultWhite) !important;
}
/* new-css */

.tab-dev-style .MuiTabs-scroller .MuiTab-textColorPrimary {
  font-weight: bold !important;
  text-transform: capitalize !important;
  color: #000 !important;
  font-size: 16px !important;
  border-radius: 8px !important;
  padding: 10px 15px !important;
  height: auto !important;
  line-height: 1 !important;
  min-height: auto !important;
}

.tab-dev-style .MuiTabs-scroller .Mui-selected {
  border: none !important;
}

.tab-dev-style .MuiTabs-scroller .css-1aquho2-MuiTabs-indicator {
  display: none !important;
}

/* .tab-dev-style {
  top: 76px!important;
  padding: 10px 6px;
} */

.tab-dev-style {
  top: 76px !important;
  /* padding: 13px 6px 10px; */
  padding: 13px 6px 0px;
  border-bottom: solid 5px #fff;
}

.tab-dev-style .css-1kubori-MuiTabs-root {
  min-height: initial;
}

/* .menu-head-title .dev-content-row {
  padding-top: 84px;
} */

.dev-menu-scroller {
  padding-top: 84px;
}

/* .dev-menu-scroller {
  margin-top: 84px;
} */

/* 14/7/23 */

.braintree-options-list .braintree-option span {
  font-size: 14px !important;
}

/* 14/7/23 */

.top-search-cuisines li {
  padding: 12px 9px;
  border-bottom: 1px solid #0000002b;
}

.top-search-cuisines li svg {
  margin-right: 8px;
}

.top-search-cuisines li:last-child {
  border: none;
}

.top-search-cuisines {
  height: calc(100% - 100px);
  overflow-y: auto;
  padding-bottom: 10px;
}

.top-search-cuisines h6 {
  font-weight: bold;
  margin-bottom: 12px;
}

.menu-desc {
  /* min-height: 48px; */
}

.Toastify__toast {
  font-size: 16px !important;
}

.Toastify__toast-container {
  /* max-width: 600px; */
  /* min-width: 400px; */
  width: 100%;
  display: inline-flex;
  justify-content: center;
}

.swal2-confirm:focus,
.swal2-cancel:focus {
  box-shadow: none !important;
}

.picktime-mobile-picktime.change_date_time_overlay {
  width: 378px;
}
.mobile-x-icon {
  display: none;
}
.desktop-x-icon {
  display: visible;
}

.MuiTabs-flexContainer {
  align-items: center !important;
}

/* .place-order-bottom-btn{
  margin-top: 396px;
} */

.place-order-desktop {
  height: calc(100vh - 196px);
}
.back_arrow-1 {
  display: none !important;
}

/* .header-section{
  animation: auto ease 0s 1 normal none running none;
} */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.load-with-animation {
  animation: fadeIn 0.5s;
}

.load-with-animation-medium {
  animation: fadeIn 1s;
}

.load-with-animation-high {
  animation: fadeIn 2s;
}

.menu-heading {
  margin-bottom: 13px;
}

.menu-heading h2 {
  font-size: 22px;
  font-weight: 700;
}

.restra-container h3 {
  font-size: 20px;
  font-weight: 700;
}

.header-notificatin-wrapper .shopping-cart-items {
  /* height: auto;
  max-height: 400px;
  overflow-y: scroll; */
  height: 100vh;
  max-height: 300px;
  overflow-y: scroll;
}

.header-notificatin-wrapper ul li {
  list-style-type: none;
  padding: 16px 0;
}

.shopping-cart-items li {
  border-bottom: 1px solid #97979794;
  width: 100%;
}

.shopping-cart-items li .item-quantity {
  align-items: center;
  background: #000;
  border-radius: 5px;
  color: #fff;
  display: flex;
  font-size: 13px;
  height: 30px;
  justify-content: center;
  margin-top: 3px;
  width: 30px;
}

.shopping-cart-items li .cart-item {
  padding: 0 12px;
  width: calc(100% - 40px);
}

.shopping-cart-items li .cart-item span {
  display: block;
  font-family: Inter;
}

.item-not-available {
  margin-bottom: 0 !important;
  padding-bottom: 0;
  color: #cc0000 !important;
}

.restra-container h3 a {
  color: #212529;
}

.restra-container h3 a:hover {
  color: #f06f00;
}

/* My account */
.account-sidenav {
  background-color: #fff;
  height: 100%;
  overflow-x: hidden;
  padding-top: 0px;
  transition: all 0.3s ease-in-out !important;
  z-index: 16;
  box-shadow: rgb(0 0 0 / 20%) 0px 8px 24px;
}

.account-sidenav li a {
  display: block;
  width: 100%;
  padding: 13px 20px;
  color: #1c1714 !important;
  font-size: 17px;
  font-family: "Inter";
  font-weight: 700;
}
.account-sidenav li a span {
  display: inline-block;
  /* margin-right: 16px; */
  width: 23px;
}
.account-sidenav li {
  border-bottom: 1px solid #00000012;
}
.account-sidenav li a span img {
  filter: brightness(0.2);
}
.account-sidenav li small {
  margin-left: 7px;
}
.my-account-page {
  padding: 40px 0;
}
.my-account-wrapper {
  background: #fff;
  box-shadow: rgb(0 0 0 / 20%) 0px 8px 24px;
  padding: 20px;
  height: 100%;
}
.my-account-wrapper {
  background: #fff;
  box-shadow: rgb(0 0 0 / 20%) 0px 8px 24px;
  padding: 20px;
  height: 100%;
  min-height: 500px;
}
.account-heading h2 {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 9px;
}

.account-heading {
  border-bottom: 1px solid #0000001f;
}
.account-body {
  padding: 21px 6px 7px;
}
.account-sidenav li a.active {
  background: var(--btnColor);
  color: #fff;
}

.account-sidenav li a.active small {
  color: #fff;
}

.account-sidenav li a.active img {
  filter: brightness(0) invert(1);
}

/* address-sec */

.address-sec .add-ts {
  border: 1px solid #52333382;
  border-radius: 11px;
  flex: 0 0 50%;
  max-width: calc(50% - 14px);
  margin-bottom: 10px;
  margin: 7px 7px;
}
.address-sec .add-ts .icon-caption {
  width: calc(100% - 68px);
}
.address-sec .add-ts .address-zone {
  padding: 15px 11px 20px 11px;
}
.address-sec .add-ts figure {
  width: 50px;
  height: 50px;
  border: 1px solid #1a0d0d30;
  border-radius: 100%;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.address-right .signup-subscribe {
  flex: 65%;
  max-width: 65%;
}
.account-body .common-btn {
  font-size: 18px;
  padding: 12px 48px;
}
.account-body .autocomplete-dropdown-container {
  position: absolute;
}
.address-sec ul {
  max-height: 468px;
  overflow-y: auto;
}
.address-sec .add-ts figure img {
  filter: grayscale(2) opacity(0.5);
}
.address-sec .add-ts .icon-caption p {
  font-size: 14px;
  color: #000000d1;
  font-weight: 700;
  font-family: "Inter";
}
.add-ts .signup-subscribe .form-check label {
  font-size: 14px;
}
.address-sec li .address-right {
  flex: 1 1;
  max-width: 100%;
  justify-content: right;
  padding: 7px 19px;
  border-top: 2px dashed #dcdcdc;
}
/* address-sec */

/* profile */

.profile-address-sec h3 {
  font-size: 20px;
  font-weight: 700;
}

.profile-address-sec {
  padding: 23px 0px 0px;
}

.profile-address-sec li h6 {
  margin: 0px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Inter";
  width: 75%;
  line-height: 20px;
}

.profile-address-sec li {
  padding: 8px 0px;
  border-bottom: 1px solid #00000040;
  align-items: center;
}

.profile-address-sec li:last-child {
  border: none;
}
.account-sidenav li a.active .menu-icons svg {
  filter: brightness(0) invert(1);
}

.profile-address-sec ul {
  max-height: 264px;
  overflow-y: auto;
}

.address-map {
  height: 231px;
}

.address-map img {
  height: 100%;
  object-fit: cover;
}
.crud-icon {
  display: flex;
  flex: 1;
  justify-content: end;
}
.crud-icon button {
  padding: 0px 5px;
}

.choose-add-modal .autocomplete-dropdown-container {
  border: none !important;
}

.address-map > div {
  height: 100% !important;
}

.change-password-sec h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.change-password-sec p {
  font-size: 16px;
  font-family: "Inter";
  color: #8b7c7c;
}
.change-password-sec li svg {
  color: var(--btnColor);
  margin-right: 11px;
}
.change-password-sec ul li {
  line-height: 32px;
  font-family: "Inter";
  position: relative;
  font-size: 16px;
}

.change-password-sec {
  margin-left: 0;
  border: 1px solid #ddd;
  padding: 13px 28px;
  border-radius: 8px;
  background: #fff9f4;
}
/* profile */

.signup-subscribe .form-check-input:checked {
  background-color: var(--btnColor);
  background-size: 13px;
  border-color: var(--commonborder);
}

.signup-subscribe .form-check-input {
  height: 23px;
  margin-right: 17px;
  width: 23px;
}

.signup-subscribe .form-check label {
  font-family: Inter;
  font-size: 16px;
  width: calc(100% - 23px);
}

.common-btn-2 {
  background: var(--btnColor);
  color: var(--defaultWhite);
  padding: 10px 17px 10px 17px;
  border-radius: 8px;
  font-size: 17px;
  border-color: var(--commonborder);
  font-weight: 700;
}

@media (max-width: 1199px) {
  .address-sec li .address-right {
    padding: 7px 15px;
  }
  .address-right .signup-subscribe {
    flex: 73% 1;
    max-width: 73%;
  }
}

@media (max-width: 991px) {
  .account-sidenav {
    display: none;
  }

  .restra-container h3 {
    font-size: 17px;
  }
}
@media (max-width: 767.98px) {
  .my-account-wrapper {
    box-shadow: none;
    padding: 0px;
  }
  .my-account-page {
    padding: 0px 0px 10px;
  }
  .address-sec .add-ts .address-zone {
    flex-direction: column;
  }
  .address-sec .add-ts .icon-caption {
    width: 100%;
    text-align: center;
    margin-top: 15px;
    padding-left: 0px !important;
  }
  .signup-subscribe .form-check-input {
    width: 19px;
    height: 19px;
    margin-right: 11px;
  }
  .add-ts .signup-subscribe .form-check label,
  .address-sec .add-ts .icon-caption p {
    font-size: 13px;
  }
  .change-password-sec {
    margin-left: 0px;
    padding: 10px;
  }

  .my-account-page {
    padding: 24px 0;
  }

  .close_position {
    position: relative !important;
    top: -15px !important;
    margin-bottom: 10px !important;
  }

  .modal-footer-cart {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid
      var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  }

  .modal-bottom-actions-cart {
    position: fixed;
    bottom: 0;
    box-shadow: 0 -2px 7px 0 rgb(0 0 0 / 22%);
    padding: 13px 19px !important;
    left: 0;
    right: 0;
    background-color: #fff;
    margin: 0;
  }
  .ck-btn {
    padding: 12px 8px;
    font-size: 17px;
    box-shadow: 0 8px 6px 0 rgb(0 0 0 / 22%);
    border-radius: 5px;
  }
}

@media (max-width: 575px) {
  .account-heading h2 {
    font-size: 21px;
    padding-bottom: 5px;
  }
  .account-body .common-btn {
    font-size: 15px;
    padding: 10px 18px;
  }
  .my-account-body span {
    font-size: 15px;
    margin-bottom: 6px;
  }
  .my-account-body .form-control {
    font-size: 14px;
  }
  .address-sec ul {
    max-height: 100%;
  }
  .address-sec .add-ts {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 7px 0px;
  }
  .crud-icon button {
    padding: 0px 5px;
    max-width: 26px;
  }

  .change-password-sec h2 {
    font-size: 20px;
  }
  .change-password-sec ul li {
    line-height: 27px;
    font-size: 14px;
  }

  .top-logo-wrapper-1 figure {
    height: 90px;
    margin: -25px auto 20px;
    width: 90px;
  }
  .back_arrow-1 {
    display: block !important;
  }
}

/* End of my account */

.address_popup_header {
  display: flex;
}
.address_popup_header span {
  margin-bottom: 8px;
  font-family: "Inter";
  text-align: left;
  font-weight: bold;
}
