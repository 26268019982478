@media (max-width: 991px) {
  .map-sec {
    height: 252px;
    margin-bottom: 33px;
  }

  .map-sec img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .track_detail {
    margin-bottom: 33px;
  }
}

@media (max-width: 767px) {
  .min-order-list {
    height: auto;
  }
}

@media (max-width: 575px) {
  .track_detail {
    margin-bottom: 25px;
  }
  .order-details-wrapper h2,
  .order-detail-instruct h2 {
    font-size: 18px;
  }
  .order-details-wrapper .cart-item p {
    font-size: 16px;
  }
  .deliver-progress h4 {
    font-size: 18px;
  }
  .order-details-wrapper .item-price {
    font-size: 18px;
  }
  .order-detail-instruct ul li {
    line-height: 28px;
    font-size: 15px;
  }
  .order-detail strong {
    font-size: 15px;
  }
  .total-wrapper {
    margin: 17px 0px;
    padding: 10px 0px;
  }
  .paid-by {
    font-size: 17px;
  }
  .paid-by strong {
    font-size: 16px;
  }
  .map-sec {
    height: 175px;
    margin-bottom: 19px;
  }
  .order-details-wrapper {
    padding: 17px 0px;
  }
  .dev-tooltip .dev-tooltiptext {
    left: auto;
    right: -100px;
    bottom: 24px;
    top: auto;
    transform: translate(0);
  }
  .mobile_small_btn {
    font-size: 14px;
  }
}
