.front-header .front-header-sec{
    max-width: 1748px;
    margin: auto;
}

.header-cart {
    background-color: var(--btnColor);
    /* padding: 7px 17px; */
    padding: 7px 20px;
    border-radius: 5px;
    position: relative;
}

.header-cart span {
    /* position: absolute;
    top: 5px;
    background: #000;
    width: 15px;
    height: 15px;
    color: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    right: 8px; */

    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -.13px;
    line-height: 1.5;
    padding-left: 8px;
    color: #fff;

}
.main-logo button{
    width: 38px;
}

/* after-login-header */

/* .header-section .nav-tabs a {
    color: #000;
    border: none;
    margin-right: 4px;
    font-size: 16px;
    font-family: 'Inter';
    font-weight: 600;
    padding: 9px 16px;
    border-radius: 32px;
} */
.selection-tabs:hover,.selection-tabs:active {
    /* background: var(--btnColor)!important; */
    background: transparent;
    color: #000;
}
.selection-tabs:hover,.selection-tabs:focus {
    /* background: var(--btnColor)!important; */
    background: transparent;
    color: #000;
}

/* .selection-tabs:hover, .selection-tabs:active, .selection-tabs:focus {
    background: transparent!important;
    color: #000!important;
    box-shadow: none!important;
} */


.selection-tabs {
    color: #000;
    border: none;
    margin-right: 4px;
    font-size: 16px;
    font-family: 'Inter';
    font-weight: 600;
    padding: 9px 16px;
    /* border-radius: 32px; */
    border-radius: 6px;
    background-color: transparent;

}
.selection-tabs.active {
    background: var(--btnColor);
    color: #fff;
}
.nav-tabs {
    border: none;
}



.timeandaddress .time-btn {
    background: transparent;
    border: none;
    color: #000;
    font-size: 15px;
}
.timeandaddress .time-btn:active {
    background: transparent;
    border: none;
    color: #000;
}
.timeandaddress .to {
    cursor: default;
}
.timeandaddress {
    margin-left: 15px;
    border-left: 1px solid #00000026;
}
.search-reasturants .form-control::placeholder{
    font-size: 17px;
}
.search-reasturants .form-control {
    border-radius: 38px;
    width: 100%;
    height: 46px;
    border: 1px solid #00000042;
    padding: 6px 21px;
}
.search-reasturants .search-icon {
    right: 13px;
    z-index: 2;
}
.search-reasturants .cross-icon {
    right: 38px;
    z-index: 2;
}
.search-reasturant-listing {
    background: #fff;
    border: 1px solid #00000026;
    max-height: 470px;
    overflow-y: hidden;
    padding-top: 68px;
    border-radius: 0px 0px 10px 10px;
    margin-top: -15px;
    box-shadow: 0 1px 7px 4px rgb(0 0 0 / 4%);
    border-top: none;
}

.search-reasturant-listing .pro_im {
    border-radius: 50px;
    overflow: hidden;
    width: 50px;
    height: 50px;
    border: 1px solid #00000021;
}

.search-product-list-title {
    width: calc(100% - 50px);
    padding-left: 13px;
}

.search-reasturant-listing ul li a {
    color: #000;
}
.search-reasturant-listing ul {
    max-height: 381px;
    overflow-y: auto;
}
.search-reasturant-listing li:hover {
    background: #efefef;
}

.search-product-list-title p {
    font-size: 14px;
    margin: 0;
    font-family: 'Inter';
    line-height: 21px;
}
.search-reasturant-listing li {
    padding: 13px 12px;
    border-bottom: 1px solid #00000026;
}

.search-reasturant-listing .pro_im img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.search-product-list-title h2 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}

.search-reasturants form {
    z-index: 2;
    margin: 0px 18px;
}
/* after-login-header */

/* header-date-picker */
.header-notificatin-wrapper{
    right: 0px;
    top: 48px!important;
    width: 450px!important;
    cursor: auto;
}
.header-notificatin-wrapper h4{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0;
    color: #000;
}
.header-notificatin-wrapper ul li {
    list-style-type: none;
    padding: 16px 0;

}
.header-cart-notification .shopping-cart-items li{
    /* cursor: pointer; */
}
.shopping-cart-items li:hover .item-quantity{
    /* background:var(--btnColor); */
}
/* .shopping-cart-items li:hover h5 {
    color: var(--btnColor);
} */
.header-login-btn span{
    position: relative;
}
.header-notificatin-wrapper ul li p {
    font-size: 15px;
    color: #3a3939;
    font-family: 'Inter';
    margin-bottom: 6px;
}
.header-notificatin-wrapper ul li strong {
    display: contents;
}
.header-notificatin-wrapper ul li:not(:last-child) {
    border-bottom: 1px solid #97979733;
}
.header-notificatin-wrapper ul li .notification_lists_timestamp {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding-top: 4px;
    font-family: 'Inter';
}
.notification_menu_wrapper ul li p {
    margin-bottom: 0;
}
.picktime-mobile-picktime ,.header-notificatin-wrapper{
    position: absolute;
    top: 76px;
    background: #fff;
    color: #000;
    padding: 18px 24px;
    /* width: 378px; */
    width: 421px;
    box-shadow: 0 1px 6px 4px rgb(0 0 0 / 6%);
}
.picktime-mobile-picktime .css-epd502, .picktime-mobile-picktime .css-xelq0e-MuiPickerStaticWrapper-content {
    width: 100%;
    min-width: 100%;
}
.picktime-mobile-picktime .dropdown-toggle {
    border: 1px solid #00000026;
    width: 100%;
    text-align: left;
    height: 45px;
    background-color: #fff;
    /* border: 1px solid #f2f2f2; */
    margin-top: 9px;
    position: relative;
    font-size: 15px;
    background-image: url("../../assets/images/time-icon.svg");
    color: #000;
    background-repeat: no-repeat;
    background-position: right 16px top 15px;
    background: #fff!important;
    border-color: #00000026!important;
}

.picktime-mobile-picktime .dropdown-toggle:active{
    color: #000!important;
}

.picktime-mobile-picktime  .dropdown-toggle::after {
    display: none;
}
.picktime-mobile-picktime .choose-date {
    padding: 12px;
    height: 45px;
    margin-bottom: 13px;
    padding-right: 50px;
}
 .choose-date{
    background-image: url("../../assets/images/calendar-icon.svg");
    background-repeat: no-repeat;
    background-position: right 16px top 15px;
    cursor: pointer;
}

.select_location_form .choose-date{
    background-image: url("../../assets/images/location.svg");
    background-repeat: no-repeat;
    background-position: right 16px top 15px;
    cursor: pointer; }



   .addressLists li.active:before{
        content:"";
        background-image: url("../../assets/images/location.svg");
        background-repeat:no-repeat;
        width:10px;
        height:15px;
        background-size:100%;
        position:absolute;
        left:0;    top: 15px;
    }

    .addressLists li:before{
        content:"";
        background-image: url("../../assets/images/location.png");
        background-repeat:no-repeat;
        width:15px;
        height:20px;
        background-size:100%;
        position:absolute;
        left:0;
    }



.choose-date::placeholder{
    color: #000!important;
}
.transparent-btn {
    background: transparent!important;
    color: #000!important;
    border-color: #000!important;
}
.picktime-mobile-picktime .dropdown-menu {
    position: initial!important;
    transform: translate(0)!important;
    margin-top: -55px;
    max-height: 330px;
    overflow-y: scroll;
    border: 1px solid #ced4da;
    border-radius: 0px;
    padding-top:0;
    padding-bottom:0;
}

.picktime-mobile-picktime .dropdown-menu .dropdown-item {
    border: none;
    /* color: rgb(153, 153, 153); */
    font-size: 14px;
    padding: 6px 14px;
}


.picktime-mobile-picktime h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 17px;
}


.timeandaddress .css-sf5t6v-PrivatePickersSlideTransition-root-MuiDayPicker-slideTransition {
    min-height: 230px;
}

.picktime-mobile-picktime .css-qklzlb-MuiDayPicker-header {
    border-bottom: 1px solid #0000001f;
}
.picktime-mobile-picktime .css-qklzlb-MuiDayPicker-header .MuiTypography-caption
{
font-size: 15px;
}
.picktime-mobile-picktime .Mui-selected {
    background-color: var(--btnColor)!important;
    border-radius: 7px;
    min-width: 42px;
    height: 42px;
    color: #fff!important;

}
.picktime-mobile-picktime .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root, .picktime-mobile-picktime .css-195y93z-MuiButtonBase-root-MuiPickersDay-root{
    width: 42px;
    height: 42px;
    border-radius: 7px;
    font-size: 15px;
}

.basic-datepicker .MuiPickersCalendarHeader-root {
    padding: 0px;
}
.basic-datepicker .MuiPickersCalendarHeader-switchViewButton {
    display: none;
}


.basic-datepicker .MuiPickersArrowSwitcher-root {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

.basic-datepicker {
    position: relative;
}

.basic-datepicker .MuiPickersCalendarHeader-labelContainer{
    position: relative;
    z-index: 9;
    margin-left: auto;
}

.autocomplete-dropdown-restaurant {
    padding: 0 6px;
    max-height: 250px;
    overflow-y: auto;
    margin-top: 15px;
}

.autocomplete-dropdown-restaurant span {
    font-size: 15px;
    line-height: 31px;
}

.header-wrapper {
    flex: 75%;
    max-width: 75%;
}
.search-login-wrapper {
    max-width: 100%;
    justify-content: end;
    width: 500px;
}
.search-reasturants{
    width: 100%;
}
.basic-datepicker .css-169iwlq-MuiCalendarPicker-root {
    width: 100%;
}
.header-login-btn span img{
    cursor: pointer;
}
.notification_dot {
    background: #f06f00;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 10px;
    position: absolute;
    right: 0px;
    top: 3px;
    width: 10px;
}


.header-login-btn .header-btn1 {
    background: transparent;
    border: none;
    padding: 2px 6px;
    position: relative;
    text-align: left;
}

.header-login-btn .header-btn1:hover {
    background: transparent;
    border: none;
}

/* .header-login-btn  span{
    cursor: pointer;
} */
/* header-date-picker */

.cart-header-radio {
    background: var(--btnColor);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 19px;
    height: 19px;
    font-size: 11px;
    position: absolute;
    right: -9px;
    top: -3px;
}
.header-notificatin-wrapper .shopping-cart-items {
    max-height: 400px;
    height: auto;
}

.header-notificatin-wrapper .order-item-details > div {
    padding: 6px 0px;
}
.header-btn1:active {
    color: #fff!important;
}



.no-data-time-piker .dropdown-menu {
    margin-top: 0;
    border: none;
}

