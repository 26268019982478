
.chat-support-form {
  width: 100%;
  height: 50vh;
}
@media only screen and (max-width: 600px) {
  .chat-support-form {
    height: 100vh;
  }
}
