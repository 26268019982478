@media (max-width: 767.98px) {
  .modal-content {
    position: absolute;
    top: 0;
    border-radius: 0px !important;
    bottom: 0;
    left: 0;
    right: 0;
    background-clip: initial;
    border: 0;
  }
  .choose-option .choose-option-inner {
    padding: 14px 19px;
  }
  .nesting-modal .MuiFormGroup-root {
    padding: 5px 0px;
  }
  .nesting-footer {
    margin: 0;
  }

  .modal-header .btn-close {
    right: 23px;
  }
  .nesting-modal {
    /* padding: 26px 0px; */
    justify-content: flex-start;
    border-radius: 0px;
    top: -71px;
  }
  .nesting-modal h4 {
    padding: 7px 5px;
  }
  .nesting-wrapper {
    max-height: calc(100% - 139px);
  }
  .modal-dialog {
    padding: 0px;
    margin: 0;
    border: none;
    max-width: 100%;
  }

  .modal {
    padding: 0px !important;
    border: none !important;
    background-color: #fff;
  }
  .build-own h4 {
    font-size: 16px;
  }
  .build-own label {
    font-size: 20px;
  }
  .modal-bottom-actions {
    position: fixed;
    bottom: 0;
    box-shadow: 0 -2px 7px 0 rgb(0 0 0 / 22%);
    padding: 17px 15px;
    left: 0;
    right: 0;
    background-color: #fff;
    margin: 0;
  }
}

@media (max-width: 575.98px) {
  /* .first-level-modal .modal-body .with-addon-image.with-desc {
    padding: 83px 8px 20px 5px !important;
  } */

  /* .first-level-modal .modal-body .add-cart-inner-modal{
    padding: 70px 8px 20px 5px !important;
  } */

  .p-item:last-child {
    margin-top: 14px;
}

  .product-modal-heading {
    /* position: fixed; */
    width: 100%;
  }
  .cross-btn {
    top: 11px !important;
  }
  .modal-bottom-actions .common-btn {
    font-size: 14px;
  }
  .nesting-modal {
    position: fixed;
    top: 0;
  }
  .quantity-actions {
    max-width: 141px;
    flex: 141px 1;
  }
  .add-cart-inner-modal .quantity-actions {
    max-width: 133px;
    flex: 138px 1;
  }
  .rest-list-modal .modal-title,
  .add-cart-modal .modal-title {
    font-size: 20px;
  }
  .add-cart-modal .modal-header span {
    font-size: 14px;
  }
  .add-cart-modal .form-label {
    font-size: 18px;
    margin-bottom: 11px;
  }

  .quantity-actions .form-control {
    height: 45px;
  }

  .mobile-btn {
    font-size: 12px;
    white-space: nowrap;
  }
}
