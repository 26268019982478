.main-footer{
    background-color: #1c1b1a;
    padding: 45px 0 50px;
    color: #fff;
}
.main-footer .location-menus li a{
    color: #ffffff73;
}
.main-footer .location-menus h3 {
    margin-bottom: 19px;
}
.copyright {
    text-align: center;
    padding: 14px 0px;
    background: #282625;
    color: #b1abab;
}

.location-menus li {
    font-family: Inter;
    font-size: 17px;
    font-weight: 400;
    line-height: 31px;
}
.location-menus h3 {
    font-size: 24px;
    font-weight: 700;
}