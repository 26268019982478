@media(max-width:991.98px)
{
    .sidenav .dropdown-menu {
        width: 75%;
        border-radius: 0px;
        margin-left: 41px;
    }
    .sidenav .dropdown-menu a {
        padding: 11px 11px;
        border-bottom: 1px solid #00000012;
    }
    .sidenav .dropdown-menu a img {
        width: 17px;
    }
}

@media(max-width:767px)
{
   /* .sidenav li a[href='/new/my-account']
    {
        display: none;
    } */
}

@media(max-width:575px)
{
    .sidenav{
        z-index: 111;
    }
    .close-menu-new {
        top: 164px;
    }
    .sidenav {
        width: 100%;
        padding-top: 8px;
    }
    .sidenav small {
        display: block;
        opacity: 1;
        top: 50%;
        left: 49px;
        transform: translateY(-50%);
    }
    .sidenav:hover{
        width: 100%;
    }

}