@media (min-width: 2400px) {
  .default-back {
    height: calc(100vh - 543px) !important;
  }
}

@media (max-width: 1920px) {
  .checkout-sec {
    padding-right: 100px;
  }
}

@media (max-width: 1400px) {
  .common-heading h2 {
    font-size: 33px;
  }
  .order-detail span {
    width: 36%;
    flex: 0 0 36%;
    max-width: 36%;
  }
}

@media (max-width: 1199.98px) {
  .common-heading h2 {
    font-size: 30px;
  }

  .menu-figure-wrapper .menu-figure-caption {
    width: 127px;
    height: 127px;
    /* min-width: 90px; */
  }

  .menu-card-sec .menu-price h6 {
    font-size: 16px;
  }
  .menu-figure-wrapper .menu-figure.no-product-restaurant {
    width: calc(100% - 127px);
  }
  .menu-card-sec .menu-item {
    min-height: 129px;
  }
  .menu-card-sec .menu-desc span {
    font-size: 14px;
  }
  .menu-card-sec .menu-name h5 {
    font-size: 16px;
  }
  .menu-figure-wrapper .menu-figure {
    width: calc(100% - 127px);
    padding-right: 10px;
  }

  /* .main-container {
        margin-top: 102px;
    } */
  .modal-dialog {
    max-width: 582px;
  }

  .order-detail {
    font-size: 13px;
  }

  /* header{
        position: initial;
    } */

  .braintree-sheet__content--form {
    flex-wrap: wrap;
  }
  .braintree-dropin .braintree-form__field-group > label {
    display: flex;
  }
}

@media (max-width: 991.98px) {
  .tab-dev-style {
    top: 123px !important;
    /* padding: 6px; */
  }

  .dev-menu-scroller {
    padding-top: 130px;
  }
  .common-heading h2 {
    font-size: 27px;
  }

  .rest-detail.ticker-visible {
    margin: 144px 0px 0px;
  }

  .timeandaddress.ticker-visible {
    top: 106px;
  }

  .delivery_time {
    left: 15px;
  }

  .cancel-order-status .success-place-order-btn {
    padding: 10px 21px !important;
    font-size: 14px !important;
  }

  .order-detail-instruct {
    margin-bottom: 10px;
  }

  .map-sec {
    height: auto !important;
    margin-bottom: 20px;
  }

  .checkout-sec {
    padding-right: 0;
  }

  /* .picktime-mobile-picktime, */
  .header-notificatin-wrapper {
    max-height: 340px;
    overflow-y: auto;
  }

  .map_space {
    padding-left: 0px;
  }

  .feature-slider .slick-prev {
    left: -24px;
  }

  .feature-slider .slick-next {
    right: -24px;
  }

  .feature-slider .slick-dots li {
    margin: 2px;
  }

  .sidenav.open {
    height: calc(100% - 72px) !important;
  }

  .sidenav.visible-msg {
    height: calc(100% - 108px) !important;
  }

  .header-notificatin-wrapper.minicart_header {
    top: 69px !important;
    overflow-y: inherit;
    max-height: initial;
  }

  .rest-cart .back_arrow {
    margin-bottom: 20px !important;
  }
  .buy-gift-card-sec .rest-detail {
    margin: 70px 0px 0px;
  }

  .mobile_cart_list {
    height: calc(100vh - 230px);
  }
  .rest-not-found {
    height: auto;
  }

  .search-reasturants .form-control {
    height: 40px;
  }

  .view-cart-wrapper h6 {
    font-weight: 600;
    text-shadow: none !important;
    font-size: 15px;
    margin-bottom: 0;
  }

  .view-cart-wrapper .view-item-wrapper {
    flex: 1;
    padding: 0 8px;
  }
  .less_top_space {
    margin-top: 68px;
  }

  .fix_detail_content .order-detail {
    font-size: 15px;
  }

  .desktop_min_height {
    min-height: calc(100vh - 425px);
  }

  .order-service-fees span {
    border: none;
    padding-right: 0;
  }

  .order-service-fees .service_text {
    margin-left: 0;
  }

  .main-logo {
    max-width: 147px !important;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .choose-time-flex {
    position: relative;
  }
  .picktime-mobile-picktime.address_picker_main {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .rest-detail.modal-appier-fixed-section {
    flex: 1;
    max-width: calc(100% - 360px);
    width: calc(100% - 360px);
  }
  .scroller-fixing {
    position: fixed !important;
    top: 0 !important;
    z-index: -1 !important;
  }

  .desktop_min_height {
    min-height: calc(100vh - 455px);
  }
}

@media (max-width: 767.98px) {

  .mobile-x-icon {
    display: block;
  }
  .desktop-x-icon {
    visibility: hidden;
  }

  .desktop_min_height {
    min-height: initial;
  }

  .addons-modal-banner {
    height: 270px;
  }

  .menu-heading h2 {
    font-size: 19px;
  }
  .banner-input-sec .search-btn {
    border-color: transparent;
  }
  .timeandaddress.ticker-visible {
    top: 96px;
  }
  .main-container.ticker-visible {
    margin-top: 119px;
  }

  .purchase-gift-sec.checkout-sec.gift_less_bottom_space {
    padding-bottom: 100px;
  }

  .rest-list-modal .modal-body .add-cart-inner-modal {
    max-height: calc(100vh - 448px);
    padding-bottom: 0;
    padding-right: 0;
  }
  .rest-list-modal .modal-content {
    overflow: hidden;
  }

  .main-container {
    height: calc(100vh - 81px);
    overflow-y: auto;
    padding-bottom: 57px;
  }
  .main-container-new {
    height: calc(100vh - 81px);
    /* overflow-y: auto; */
    padding-bottom: 57px;
  }
  .modal-header {
    padding: 11px 31px;
  }
  .modal-dialog {
    height: 100%;
  }
  .modal-header .btn-close {
    right: 24px;
    top: 21px;
  }
  .modal-dialog {
    max-width: 100%;
  }

  .add-gift-amount .track_circle {
    width: 19px;
    height: 19px;
  }
  .add-gift-amount.track_detail ul::before,
  .add-gift-amount.track_detail ul li:first-child:before,
  .add-gift-amount.track_detail ul li:last-child:after {
    top: 30%;
  }
  .common-gift-btn button {
    max-width: 100%;
    min-width: auto;
    width: 100%;
    padding: 13px 18px !important;
    font-size: 15px !important;
  }
  .common-gift-btn {
    position: fixed;
    bottom: 0;
    z-index: 16;
    background: #fff;
    width: 100%;
    left: 0;
    right: 0;
    padding: 21px 8px;
    box-shadow: 0 -2px 7px 0 rgb(0 0 0 / 22%);
  }

  .purchase-gift-sec.checkout-sec {
    padding-bottom: 170px;
  }

  .dev-tooltip .dev-tooltiptext {
    left: 20px;
    top: -50%;
    transform: translateY(-50%);
  }

  .close_position {
    position: relative;
    top: -10px;
    margin-bottom: 010px;
    z-index: 1111;
    width: 50px;
  }
  .mobile_search_back,
  .mobile_search_back:hover,
  .mobile_search_back:focus,
  .mobile_search_back:active {
    background: transparent;
    border: none;
    color: #000 !important;
    left: 0;
    right: auto !important;
    z-index: 9999 !important;
    box-shadow: none;
  }

  .mobile_search_ss {
    padding: 20px 20px 20px 60px !important;
  }

  .mobile_search_ss .cross-icon {
    margin-right: 10px;
  }

  .mobile_search_ss .search-icon,
  .mobile_search_ss .cross-icon {
    top: auto !important;
    bottom: -3px;
  }

  .mobile_search_ss .form-control {
    padding-right: 70px;
  }

  .search-reasturants .form-control {
    border: 1px solid #00000026;
    height: 45px;
  }

  .search-reasturants .back-icon {
    display: block;
    position: absolute;
    top: 21px;
    margin-left: 7px;
  }

  .search-reasturants .search-icon {
    display: block;
    position: absolute;
    top: 21px;
    margin-left: 7px;
  }

  .cancel_order_modal .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cancel_order_modal .btn.btn-primary {
    width: auto;
    min-width: 120px;
  }

  .close-restra strong {
    padding: 0 10px;
    text-align: center;
    font-size: 14px;
    text-shadow: none;
  }
  .menu-card-sec .menu-price h6 {
    font-size: 15px;
  }
  .menu-card-sec .menu-item {
    padding: 0px 0px 0px 17px;
    border-radius: 10px;
    /* background: #ff00000d!important; */
    /* opacity: 0.8; */
    box-shadow: 0 0 7px 0 rgb(0 0 0 / 11%);
  }
  /* .search-reasturants h6 {
    margin-top: 26px;
} */

  .mobile_search_ss .cross-icon {
    right: 38px;
    z-index: 2;
    bottom: -2px;
  }
  .sidenav.open {
    height: 100% !important;
    padding-bottom: 94px;
  }

  .progressBar.gift_card_progress_bar li:before {
    top: 16px !important;
  }

  .header-notificatin-wrapper.minicart_header {
    top: 69px !important;
    left: auto;
    right: 5px;
    height: auto !important;
    bottom: auto;
    width: 300px !important;
    padding-top: 32px;
  }

  .section_space {
    padding: 28px 0;
  }

  .main-logo .for_mobile {
    width: 28px;
  }

  .mobile_cart_list {
    height: calc(100% - 236px);
  }

  .mobile_height_none {
    height: auto !important;
  }

  .not-found-container {
    height: calc(100vh - 140px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-loader {
    flex: 0 0 30px;
    width: 24px !important;
    height: 24px !important;
    flex: 0 0 24px !important;
  }

  .page-loading-new {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .mobile-cart-option .header-notificatin-wrapper {
    height: 100vh;
  }

  .mobile-cart-option .header-notificatin-wrapper.minicart_header {
    height: 100% !important;
    position: fixed !important;
    z-index: 999;
    top: 0 !important;
    left: 0;
    width: 100% !important;
    right: 0;
  }

  .mobile-cart-option .header-notificatin-wrapper.minicart_header ul {
    height: calc(100% - 110px);
    max-height: initial !important;
    overflow-y: scroll;
  }

  .mobile-cart-option
    .header-notificatin-wrapper.minicart_header
    .shopping-cart-items {
    max-height: initial !important;
    height: 100% !important;
  }

  .delivery-address .location-search-input {
    padding-right: 46px;
  }

  .delivery-address .location-search-input + svg {
    margin-top: -5px;
  }
  .bottom-equal-space {
    margin-bottom: 64px;
  }

  .schedule_date_time_modal {
    max-height: initial;
    height: calc(100vh - 60px);
  }

  .without-hamburger .hamburger_btn {
    display: none !important;
  }

  .without-hamburger .main-logo a img {
    max-height: 36px;
  }

  .splace_screen {
    padding: 20px;
    text-align: center;
    position: fixed;
    background: #fff;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .splace_screen p {
    font-size: 20px;
    padding: 15px;
    font-weight: 600;
  }

  .customize-success {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .shopping-cart-items li .cart-item.reduce_for_price {
    width: calc(100% - 108px);
    flex: 1;
  }
  .order-details-wrapper .amount_wrap {
    display: flex;
    justify-content: end;
    flex: 1;
  }

  .product_img_wrap {
    flex: 0 0 113px;
    max-width: 113px;
  }

  .bottom-equal-space {
    padding-bottom: 124px !important;
  }
  .rest-detail.section_space.less_top_space {
    padding-bottom: 46px;
  }
  .braintree-dropin .braintree-form__field-group > label {
    display: block;
  }

  .braintree-dropin
    .braintree-form__field-group
    > label
    input[type="checkbox"] {
    margin-top: 3px !important;
  }

  .tab-dev-style {
    top: 113px !important;

    /* top: 100px!important; */
    /* padding: 6px; */
  }
}

@media (max-width: 575.98px) {
  .rest-list-modal .modal-body .add-cart-inner-modal {
    max-height: calc(100vh - 395px);
  }

  .lazy-page-loading-new {
    width: 100%;
    padding: 100px;
    display: block;
    z-index: 111;
    position: relative;
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    height: 100vh;
  }

  /* .scroller-header.fixed-top{
  animation: none!important;
}
.header-section{
  animation: none!important;
  transition: all 0s ease-in-out!important;
} */

  /* @keyframes slide-down {
  0% {
    opacity: 1;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
} */
  .tab-dev-style {
    top: -2px !important;
  }
  .scroller-header.fixed-top {
    /* top: 0; */
    top: -100%;
    /* position: initial!important;
  animation: none!important;
  transition: 0!important; */
  }

  .picktime-mobile-picktime.change_date_time_overlay {
    width: 100%;
  }

  .addons-modal-banner {
    height: 250px;
    /* margin-top: 128px; */
  }
  .addons-modal-banner.no-desc {
    margin-top: 0px;
  }
  /* .rest-list-modal .modal-body .add-cart-inner-modal.with-addon-image {
    max-height: calc(100vh - 145px);
} */

  .rest-list-modal .modal-body {
    padding: 0px 15px 38px;
  }

  .rest-list-modal .modal-body .add-cart-inner-modal.with-addon-image {
    max-height: calc(100vh - 200px);
    /* max-height: calc(100vh - 230px); */ /* TODO */
  }

  .search-product-list-title h2 {
    font-size: 16px;
  }

  /* .tab-dev-style {
    /* top: 102px!important; */
  /* top: 102px!important; */
  /* padding: 6px; */
  /* } */

  .cross-btn svg {
    font-size: 17px;
  }

  .dev-menu-scroller {
    /* padding-top: 123px; */
    padding-top: 13px;
  }

  .menu-heading h2 {
    font-size: 18px;
  }
  .header-login-btn a:last-child {
    background: #fff;
    /* color: #000 !important; */
  }

  .header-section .main-logo img {
    filter: none;
  }

  .rest-detail.section_space.less_top_space {
    padding-bottom: 76px;
  }
  .less_top_space {
    margin-top: 60px;
  }

  /* .rest-detail.ticker-visible {
    margin: 131px 0px 0px;
} */

  .rest-detail.ticker-visible {
    margin: 99px 0px 0px;
  }

  .main-container.ticker-visible {
    margin-top: 98px;
  }
  .timeandaddress.ticker-visible {
    top: 90px;
  }
  .header-login-btn a {
    padding: 7px 10px 8px 10px;
    font-size: 15px;
    margin-right: 6px;
    /* color: #000;
    border: none; */
  }
  .fixed-top .header-login-btn a {
    /* border: none;
    color: #000; */
    /* border: 1px solid var(--btnColor); */
    /*  color: var(--btnColor); */
    color: #000;
  }
  /* .rest-list-modal .modal-body .add-cart-inner-modal {
        max-height: 431px;
    } */

  .header-section.only-home .main-logo img {
    /* filter: none; */
  }
  .add-cart-inner-modal .MuiFormControlLabel-label {
    font-size: 14px !important;
  }
  .cancel-order-img img {
    width: 60px;
    height: 60px;
  }

  .cancel-order-status button {
    width: 100%;
  }

  .cancel-order-status {
    max-width: 330px;
    margin: 0 auto;
  }

  .congrats-inner {
    margin-top: -31px;
  }

  .order-item-details > div {
    padding: 6px 0px;
    line-height: 25px;
  }
  .order-item-details > div strong {
    font-size: 15px;
  }
  .order-item-details > div span {
    font-size: 14px !important;
  }
  .purchase-gift-sec .form-label {
    font-size: 16px;
  }
  .my-account-body strong {
    font-size: 15px;
  }
  .my-account-body .detail {
    margin-bottom: 13px;
  }
  .modal-header {
    padding: 13px 18px;
  }

  .modal-header .modal-title {
    font-size: 18px;
    padding-right: 34px;
  }
  .modal-body {
    padding: 0px 22px 72px;
  }
  .navigate-close {
    position: absolute;
    top: 19px;
  }
  .form-control {
    padding: 10px 14px;
    height: 47px;
  }

  .phone-field .input-group-prepend {
    height: 47px;
  }

  .phone-field .form-control {
    height: 47px;
  }

  .common-heading h2 {
    font-size: 20px;
  }

  header {
    padding: 12px 0px;
    /* background-color: #fff; */
  }
  textarea.form-control {
    height: 89px !important;
  }
  .thanks-page h2 {
    font-size: 22px;
  }
  .success-check > div {
    width: 100px !important;
    height: 100px !important;
  }
  .circle-process {
    margin-left: 10px;
    top: 12px;
    right: 25px;
  }
  .main-container {
    margin-top: 62px;
  }
  .login-sec.forgot-sec {
    padding-top: 24px;
  }

  .login-sec.forgot-sec,
  .login-sec.otp-verfication {
    margin-top: 0px;
    padding-top: 97px !important;
  }

  .login-sec.for-mobile {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background: #fff;
    bottom: 0;
    z-index: 110000;
    padding-top: 30px;
    overflow-y: auto;
  }

  .login-sec.for-mobile .wrapper {
    border-top: none;
  }
  .dev-tooltip .dev-tooltiptext {
    left: auto;
    right: -100px;
    bottom: 24px;
    top: auto;
    transform: translate(0);
  }

  .meet_at_door {
    float: none;
    display: inline-block;
    position: initial;
    line-height: 1;
    padding: 10px 14px;
  }

  .track_detail ul li {
    flex-grow: initial;
    flex: 0 0 80px;
    max-width: 80px;
  }

  .track_detail ul {
    justify-content: center!important;
  }

  .braintree-options-list .braintree-option {
    width: 58px;
  }
  .braintree-options-list {
    display: flex;
    flex-wrap: wrap;
  }

  .dev-cod {
    /* width: 77px!important; */
    width: 58px !important;
  }

  /* .progressBar.gift_card_progress_bar li:after,.progressBar.gift_card_progress_bar li:before {
        display: none;
    } */

  .progressBar.gift_card_progress_bar {
    max-width: 380px !important;
    justify-content: space-between;
  }

  .progressBar.gift_card_progress_bar li {
    flex-grow: 1 !important;
    flex: 0 0 auto;
    max-width: initial;
  }
  .braintree-method .braintree-method__label {
    margin-left: 6px;
    font-size: 13px;
  }
  .braintree-method {
    padding: 8px 10px 8px 10px;
  }

  .header-notificatin-wrapper.minicart_header {
    top: 62px !important;
    background-color: #f9f9f9;
  }
  .header-notificatin-wrapper .shopping-cart-items ul {
    max-height: 236px;
  }

  .newsletter-sec .form-control {
    padding-right: 100px;
  }
  .order_loader {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    margin-left: 5px !important;
  }

  .buy-gift-card-sec .rest-detail {
    margin: 60px 0px 0px;
  }

  .main-logo .for_mobile {
    width: 24px;
    margin-top: -5px;
  }
  .gift_card_progress_bar {
    margin: auto !important;
  }

  .delete-case-modal h6 {
    font-size: 16px;
  }

  .delete-case-modal p {
    font-size: 15px;
  }
  .mobile_change_direction {
    flex-direction: column-reverse !important;
  }

  .mobile_change_direction .cancel_btn {
    margin-bottom: 0 !important;
    margin-top: 15px !important;
  }
  .fix_detail_content .order-detail {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-bottom: 10px;
  }

  .fix_detail_content .order-detail span {
    flex: 0 0 40%;
    max-width: 40%;
    width: 40%;
    line-height: 1.7;
  }

  .fix_detail_content .order-detail strong {
    flex: 1;
    line-height: 1.5;
    font-size: 14px;
  }

  .shopping-cart-items li .cart-item p {
    font-size: 14px;
  }
  .deliver-progress h4 {
    font-weight: 600;
  }

  .order-detail-cancel {
    margin: 20px 0;
    padding: 15px;
  }

  .order-detail-cancel span p {
    font-size: 16px;
    line-height: 1.4;
    padding-left: 13px;
  }

  .order-detail-cancel img {
    max-height: 46px;
  }

  .change_date_time_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transform: translate(0);
    max-width: 100%;
    max-height: initial;
    height: 100vh;
    width: 100%;
    box-shadow: none;
    z-index: 9991;
    /* padding-bottom: 140px; */
    overflow-y: initial;
  }

  /* .change_date_time_overlay .schedule-btn {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        max-width:100%;
        padding:20px;
        margin: auto;
        background-color:#fff;
    } */

  .mobile_verticle_height {
    height: calc(100vh - 240px);
    overflow-y: auto;
    margin-bottom: 20px;
    overflow-x: hidden;
  }

  .mobile_verticle_height .css-169iwlq-MuiCalendarPicker-root {
    max-height: initial !important;
  }
  .schedule_date_time_modal .mobile_verticle_height {
    height: calc(100vh - 264px);
  }

  /* .time_hide_mobile .choose-date-flex {
    display: none;
  } */
  .time_hide_mobile {
    display: none;
  }

  /* .time_hide_mobile .back-btn {
    transform: translateY(-60%) !important;
  } */
  .time_hide_mobile-new .back-btn {
    transform: translateY(-60%) !important;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  .rest-detail.less_top_space {
    margin-bottom: 0 !important;
    padding-bottom: 15px;
  }

  .section_space.less_top_space.modal-appier-fixed-section.bottom-equal-space {
    padding-bottom: 100px !important;
    margin-bottom: 15px !important;
  }

  .order-service-fees span {
    font-size: 14px;
  }

  .qty-wrap {
    display: flex;
    align-items: center;
    width: 115px;
  }
}

@media (max-width: 375.98px) {
  .common-heading h2 {
    font-size: 18px;
  }
  .restaurants-mobile-view span {
    font-size: 14px;
  }
  .add-gift-amount .progressBar li strong {
    font-size: 14px;
  }
  /* .tab-dev-style {
    top: 100px!important;
} */
  .floating-header {
    justify-content: initial;
  }
  .floating-header p {
    font-size: 13px;
  }
  .floating-header svg {
    font-size: 17px;
  }
}

@media (max-width: 324.98px) {
  .banner-sec .search-btn {
    position: initial !important;
    width: 100%;
    border-radius: 8px;
    margin-top: 9px;
    border: 1px solid #fff;
  }
  .banner-input-sec .location-search-input {
    border-radius: 8px !important;
  }

  .banner-sec .location-search-input::placeholder {
    font-size: 15px;
  }

  .banner-sec .location-search-input {
    border-radius: 0px;
    height: 42px;
    padding-right: 10px;
  }
}
@media (max-width: 412px) {
  .gif-card-container {
    min-width: 250px;
  }
}

@media (max-width: 990px) {
  .delivery-pickup,
  .checkout-edit-add,
  .gift-card-promo {
    max-width: none;
  }
}
