.shopping-cart-items {
  height: calc(90% - 268px);
  overflow-y: auto;
}

.shopping-cart-items li {
  border-bottom: 1px solid #97979794;
  padding: 17px 0;
  width: 100%;
}

.shopping-cart-items li .cart-item {
  padding: 0 12px;
  width: calc(100% - 40px);
}

.shopping-cart-items li .cart-item p {
  font-family: Inter;
  margin: 0;
}

.shopping-cart-items li .cart-item span {
  display: block;
  font-family: Inter;
}

.order-detail span {
  font-family: "Inter";
  width: 45%;
  display: inline-block;
  flex: 0 0 45%;
  max-width: 45%;
}

.order-detail {
  line-height: 37px;
  font-size: 16px;
  display: flex;
  align-items: flex-start;
}

.fix_detail_content .order-detail {
  flex: 1 1;
  padding-bottom: 12px;
  line-height: 1.5;
}

.order-details-wrapper h2 {
  font-size: 24px;
  margin: 0;
}

.order-details-wrapper {
  padding: 33px 0px;
}

.order-details-wrapper .cart-item p {
  font-size: 19px;
}

.order-details-wrapper .item-price {
  font-size: 21px;
}

.order-detail-instruct ul li {
  display: flex;
  justify-content: space-between;
  line-height: 39px;
  font-size: 17px;
  font-family: "Inter";
}

.order-detail-instruct h3 {
  font-size: 20px;
}

.order-detail-instruct h2 {
  font-size: 25px;
}

.total-wrapper {
  border-top: 1px solid #0000004a;
  border-bottom: 1px solid #0000004a;
  padding: 14px 0px;
  /* display: flex; */
  /* justify-content: space-between; */
  font-family: "Inter";
  margin: 25px 0px;
}

.total-wrapper h2 {
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-size: 19px;
}

.paid-by {
  display: flex;
  justify-content: space-between;
  /* padding-bottom: 17px; */
  font-family: "Inter";
  font-size: 19px;
}

.paid-by strong {
  font-size: 19px;
}
.order-detail-sec {
  padding: 26px 0px 30px;
}

/* order-track */

.track_detail ul li {
  flex-grow: 1;
  padding: 0 5px;
  position: relative;
}

.track_circle {
  background-color: #fff;
  width: 27px;
  height: 27px;
  border-radius: 100px;
  margin: 7px auto;
  border: 2px solid #000;
  position: relative;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  z-index: 12;
}

.track_detail ul li span {
  display: block;
  color: #a7a7a7;
}

.success-booking .track_circle {
  background-color: var(--btnColor);
  border-color: var(--btnColor);
}
.success-booking:after,
.success-booking:before {
  background-color: var(--btnColor) !important;
}
.track_detail ul li.success-booking-one:after,
.track_detail ul li.success-booking-one:before {
  background-color: #000 !important;
}

.disable-booking .track_circle {
  background-color: #cbcbcb !important;
  border: solid 2px #a3a0a0 !important;
  cursor: not-allowed;
}

.track_detail ul li.success-booking:before {
  background-color: #f06f00 !important;
}
.progressBar.gift_card_progress_bar li.success-booking:before {
  background-color: #000 !important;
}

/* .track_detail ul li:first-child:before {
    content: "";
    position: absolute;
    height: 2px;
    background: #fff;
    width: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 11;
} */

/* .track_detail ul li:after {
    content: "";
    height: 2px;
    background: #000;
    position: absolute;
    width: 50%;
    right: 0;
    top: 20px;
    left: 0;
} */

.track_detail ul li:before {
  content: "";
  height: 2px;
  background: #000;
  position: absolute;
  width: 100%;
  right: 0;
  top: 20px;
  left: auto;
  transform: translate(-50%);
}

.track_detail ul li:first-child:after,
.track_detail ul li:first-child:before {
  display: none;
}

.track_detail
  ul
  li.success-booking
  + li:before:not(.track_detail ul li:first-child) {
  background: #f06f00 !important;
}

/* .track_detail ul li:last-child:after{
    content: "";
    position: absolute;
    height: 2px;
    background: #fff;
    width: 50%;
    right:0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 11;
} */

/* .track_detail ul li:after {
    content: "";
    position: absolute;
    height: 2px;
    background: #b7b7b7;
    width: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.track_detail ul li:before {
    content: "";
    position: absolute;
    height: 2px;
    background: #b7b7b7;
    width: 50%;
    left: auto;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
} */

.track_detail ul {
  display: flex;
  text-align: center;
  list-style: none;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  position: relative;
  max-width: 736px;
  margin: 0 auto;
  z-index: 1;
}

/* .track_detail ul::before {
    position: absolute;
    content: "";
    border: 1px solid #000;
    width: 100%;
    height: 0px;
    z-index: 10;
} */

.track_detail {
  margin-bottom: 51px;
}
.deliver-progress h4 {
  font-size: 20px;
  font-family: "Inter";
}
/* order-track */

.quantity-count-order-Detail {
  width: 30px;
  height: 30px;
  background: #000;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  border-radius: 5px;
  font-size: 13px;
  cursor: text;
}
.quantity-count-order-Detail:hover {
  width: 30px;
  height: 30px;
  background: #000;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  border-radius: 5px;
  font-size: 13px;
  cursor: text;
}
.dev-tooltip .dev-tooltiptext li {
  line-height: 1.4;
  padding-bottom: 5px;
}
.dev-tooltip .dev-tooltiptext li span:first-child {
  text-align: left;
  line-height: 1.5;
  font-size: 14px;
  padding-right: 10px;
}

.cancel-btn {
  font-size: 14px !important;
  padding: 5px !important;
  border-radius: 5px !important;
  font-weight: 500 !important;
}

.common-btn {
  background: var(--btnColor);
  color: var(--defaultWhite);
  padding: 10px 17px 10px 17px;
  border-radius: 8px;
  font-size: 17px;
  border-color: var(--commonborder);
  font-weight: 700;
}
.common-btn:hover {
  background: transparent;
  border-color: var(--commonborder);
  color: var(--btnColor);
}
span.status_name {
  color: #767373 !important;
  font-size: 14px;
}
