.markar__tooltip {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  padding: 5px 12px;
  color: #fff;
  position: relative;
  padding-left: 32px;
}

.markar__tooltip::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 12px;
  width: 12px;
  background: #fff;
  border-radius: 50%;
  left: 10px;
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
}
button.gm-ui-hover-effect {
  display: none !important;
}

.gm-style .gm-style-iw-c,
.gm-style-iw-d,
.gm-style .gm-style-iw-t::after {
  background: var(--btnColor);
}

.gm-style .gm-style-iw-d {
  overflow: initial !important;
}

/* LOADER */

.app-loader-dev {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #f06f00; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.order_loader {
  width: 30px;
  height: 30px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.signup-verification-btn {
  border: none;
  background: none;
  font-weight: bold;
  color: var(--btnColor);
}

/* BRAIN-TREE STYLE START */
.braintree-show-methods .braintree-sheet--active .braintree-sheet {
  border-color: #f06f00 !important;
}
.braintreePayButton {
  margin-left: auto;
  display: flex;
}

.braintree-option__applePay .braintree-option__logo {
  width: 40px;
}

.otherway-payment-payments .braintree-heading {
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  text-transform: capitalize;
}
.otherway-payment-payments .braintree-options-list {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(15, 1fr);
  background: #fff;
  padding-top: 5px;
}

.otherway-payment-payments .braintree-options-list .braintree-option {
  background: #fff;
  border-radius: 2.5px;
  border: solid 1px #cacaca;
  background-color: #fff;
  width: 68px;
  height: 38px;
  justify-content: center;
  align-items: center;
}

.otherway-payment-payments
  .braintree-options-list
  .braintree-option
  .braintree-option__label {
  display: none;
}

.braintree-large-button {
  background: #fff;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 0;
  text-align: left;
  margin-top: 0;
  color: #f06f00;
}

.braintree-large-button span {
  border-bottom: 1px solid #f06f00;
}

.braintree-large-button:hover {
  background: #fff;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 0;
  text-align: left;
  margin-top: 0;
  color: #f06f00;
}

.braintree-option__card svg,
.braintree-option__googlePay svg,
.braintree-option__paypal svg {
  display: none;
}

.braintree-option__card .braintree-option__logo {
  content: url("../images/credit-card-icon.svg");
}

.braintree-option__googlePay .braintree-option__logo {
  content: url("../images/gpay.jpg");
  max-width: 29px;
}

.braintree-option__paypal .braintree-option__logo {
  content: url("../images/paypal.svg");
}

.braintree-sheet__content--form {
  display: flex;
}

.braintree-sheet__content--form
  .braintree-form__field-group
  .braintree-form__field
  .braintree-form__hosted-field {
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px #cacaca;
}

.braintree-dropin {
  font-family: "Lato", sans-serif;
}

.braintree-sheet__content--form
  .braintree-form__field-group
  .braintree-form__field {
  margin-right: 28px;
}
.braintree-methods--active {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  background: #fff;
}

.braintree-method {
  height: 60px;
  border-radius: 8px;
  border: solid 1px #cacaca;
  background: #fff;
  width: 100%;
}

.braintree-methods--active:not(.braintree-methods--edit)
  .braintree-method--active {
  border-radius: 8px;
  border: solid 2px #f06f00;
  background-color: #fff;
}

.braintree-method:last-child,
.braintree-method:first-child {
  border-radius: 8px;
}

.braintree-method__icon-container.braintree-method__check-container {
  display: none;
}

.braintree-placeholder {
  margin-bottom: 20px;
  display: none;
}

.braintree-loaded .braintree-upper-container {
  padding-bottom: 20px;
}

.braintree-large-button:hover span {
  border-bottom: solid 1px #f06f00;
}

.braintree-upper-container:before {
  background: #fff;
}

[data-braintree-id="apple-pay-button"] {
  width: 60px;
}

.comma:not(:first-child) {
  margin-left: -0.3em;
}

.comma:first-child:empty ~ .comma:not(:empty) {
  margin-left: 0;
}

.comma:first-child:empty ~ .comma:not(:empty) ~ .comma:not(:empty) {
  margin-left: -0.3em;
}

.comma:empty {
  display: none;
}

.comma:not(:first-child):before {
  margin-left: 6px;

  content: ", ";
}

.comma:empty + .comma:not(:empty):before {
  content: "";
}

.comma:not(:empty) ~ .comma:empty + .comma:not(:empty):before {
  content: ", ";
}

.braintree-methods--edit .braintree-method__delete-container {
  position: absolute;
  right: 15px;
}

.braintree-method {
  padding: 8px 20px 8px 20px;
}

.braintree-method__delete-container .braintree-method__delete {
  margin-right: 0;
  background: none;
  position: relative;
  width: auto;
  height: auto;
}

.braintree-method__delete-container .braintree-method__delete:hover {
  background: unset;
}

.braintree-method__delete-container .braintree-method__delete::after {
  content: "Remove";
  background: none;
  color: #f06f00;
  text-decoration: underline;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.29px;
}

.braintree-method__delete-container .braintree-method__delete > svg {
  display: none;
}
.promocode__message__wrapper {
  align-items: center;
  display: flex;
  /* margin-top: 24px; */
}

.promocode__message__wrapper .promocode__message {
  align-items: center;
  background-color: rgba(240, 111, 0, 0.1);
  border: 1px solid rgba(240, 111, 0, 0.1);
  border-radius: 8px;
  display: flex;
  margin: 0 16px 0 0;
  padding: 8px 16px;
}

.promocode__message__wrapper .promocode__message__iconBox {
  margin-right: 8px;
}

.promocode__message__wrapper .promocode__message__contentBox > p:first-child {
  color: #3a3939;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.33px;
  line-height: normal;
}

.cancel__promo span {
  color: #f06f00;
  cursor: pointer;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.29px;
  line-height: normal;
  text-decoration: underline;
}

.a-link {
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .checkout_page_main .rest-detail {
    margin-top: 64px;
  }
}

@media screen and (max-width: 991px) {
  .open-view-cart .rest-cart {
    margin-top: 0;
    top: 0;
  }
}

@media screen and (max-width: 767px) {
  .braintree-sheet__content--form {
    display: flex;
    flex-direction: column;
  }
  .braintree-sheet__content--form
    .braintree-form__field-group
    .braintree-form__field {
    margin-right: 0px;
  }
  .braintree-methods--active {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
  }

  .braintree-method {
    height: 50px;
  }
}

@media screen and (max-width: 575px) {
  .dev-tooltip .dev-tooltiptext {
    left: auto;
    right: -100px !important;
    bottom: 24px;
    top: auto;
    transform: translate(0);
  }
  .checkout_page_main .rest-detail {
    margin-top: 58px;
  }
  .mobile_small_btn {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .braintree-methods--active {
    grid-template-columns: repeat(1, 1fr);
  }

  .checkout_page_main .rest-detail {
    padding: 30px 0;
  }
}
/* BRAIN-TREE STYLE END */
/* tootltip */
.dev-tooltip {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

/* Tooltip text */
.dev-tooltip .dev-tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #ffffff;
  color: #000;
  text-align: center;
  padding: 15px 16px;
  border-radius: 6px;
  border: 1px solid lightGray;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  right: 20px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.dev-tooltip:hover .dev-tooltiptext {
  visibility: visible;
}

.tooltip-image {
  width: 30px;
  height: 30px;
}
/* tootltip */

.driver-tip-tabs ul {
  display: flex;
  /* margin-top: 10px; */
  border: 1px solid #000;
  border-radius: 6px;
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0;
  margin-bottom: 30px;
}
.driver-tip-tabs.fund-active ul {
  flex: 0 0 77%;
  max-width: 77%;
}
.driver-tip-tabs ul li {
  width: 50%;
  text-align: center;
  padding: 16px 17px;
  cursor: pointer;
  font-family: "Inter";
  border-right: 1px solid #000;
  font-size: 15px;
}

.driver-tip-tabs ul li:last-child {
  border: none;
}

.driver-tip-tabs ul li.active {
  background: #000;
  color: #fff;
}

.driver-tip-tabs {
  display: flex;
  align-items: center;
}

.driver-tip-tabs.fund-active .add-fund-input {
  flex: 1;
  max-width: 100%;
  margin: 0px 15px;
}
/* DRIVER TIP */
.tip-amount-block {
  padding: 15px 10px;
  /* border:1px solid var(--commonborder) !important; */
  border-radius: 7px;
  margin-bottom: 25px !important;
}
.tip-amount-block span {
  color: var(--btnColor);
}

.tip-none-active {
  background-color: #000 !important;
  color: #fff;
}
.top-space {
  margin-top: 5px !important;
  margin-left: 5px !important;
}

.success-place-order-btn {
  background-color: transparent;
  border: 1px solid var(--btnColor);
  color: #f06f00;
  font-size: 16px !important;
  font-weight: bold !important;
  padding: 10px 27px !important;
  /* width: 40%; */
  width: 27%;
  margin-bottom: 20px;
  /* border-radius: 30px; */
}
.success-place-order-btn:hover {
  background-color: #f06f00;
  color: #ffffff;
  border: 1px solid var(--btnColor);
}
.success-place-order-text {
  color: #f06f00;
  color: lightgray;
  font-size: 20px;
}
.success-place-order-text-cong {
  color: rgb(0, 207, 51);
  font-size: 45px;
  /* color: lightgray */
}
.place-order-btn:disabled {
  background-color: rgb(177, 177, 177) !important;
  border-color: rgb(177, 177, 177) !important;
  color: #000;
  cursor: not-allowed !important;
}

.braintree-dropin .braintree-hidden {
  height: auto !important;
  opacity: 1 !important;
  overflow: visible !important;
  visibility: visible !important;
}

.braintree-large-button.braintree-hidden {
  display: block !important;
  margin-bottom: 14px !important;
}

[data-braintree-id="methods-edit"] {
  position: initial !important;
  color: #f06f00 !important;
  font-weight: 600;
  text-decoration: none !important;
}

[data-braintree-id="methods-label"] {
  display: none !important;
}

/* Pagination */
.Mui-selected {
  /* background-color: #F06F00 !important; */
  /* color: #ffffff !important; */
  background-color: #ffe4cd !important;
  color: #000 !important;
  border: 1px solid #ffe4cd !important;
}
/* Pagination */

.order-detail-cancel {
  background-color: #fef5ed;
  border-radius: 4px;
  margin-bottom: 56px;
  margin-top: 48px;
  padding: 22.7px 24px 23.7px;
  display: flex;
  align-items: center;
}
.order-detail-cancel-not {
  padding: 7.7px 24px 7.7px !important;
}
.war-order-details-image {
  height: 50px;
  width: 50px;
}

.order-detail-cancel-para {
  color: #0e0e0e;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.21px;
  line-height: 1.67;
  margin-bottom: 0;
}

/* REDEEM REWARD POINTS */
.available-points-amount {
  background: #f3f3f3;
  border-radius: 0;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 10px;
  border-radius: 4px;
  margin-left: 178px;
}
.select-gift-card-dropdown button {
  width: 40%;
  background-color: #ffffff;
  color: #000;
  border: 1px solid #efefef;
}
/* .select-gift-card-dropdown-item{
  width:40%;
} */

/* .select-gift-card-dropdown.dropdown-menu {
  width:40%;
} */

.select-gift-card-dropdown-item dropdown-item {
  width: 40% !important;
}
.shopping-cart-items li:hover {
  color: #000 !important;
}

.shopping-cart-items li:hover .no-hover-card {
  background: #000 !important;
}

/* REDEEM REWARD POINTS */

.Toastify__toast-container {
  z-index: 12000000000 !important;
}
.cancel-modal-cross {
  position: absolute;
  right: 25px;
  top: 25px;
}

.level-alignment {
  margin-left: 43px !important;
}

.oneX-class {
  cursor: pointer !important;
}
.view-cart-wrapper.gift-go-checkout {
  padding: 8px 23px 8px 23px;
  border-radius: 10px;
}
.clear-btn {
  background-color: #f06f00;
  border: 1px solid #f06f00 !important;
}
.clear-btn:hover {
  background-color: #f06f00;
  border: 1px solid #f06f00 !important;
}

.time-btn-mob {
  background: transparent;
  border: none;
  color: #000;
  font-size: 15px;
  padding: 0;
}
.time-btn-mob:hover {
  background: transparent;
  border: none;
  color: #000;
  font-size: 15px;
  padding: 0;
}

.dev-cras {
  margin-left: 55px !important;
}

.dev-invoice {
  width: 89px !important;
}

/* floating-header */

.floating-header {
  background: var(--btnColor);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 7px 10px;
  margin-bottom: 11px;
  margin-top: -15px;
}

.floating-header p {
  margin: 0;
}

.floating-header svg {
  margin-right: 10px;
}

/* floating-header */

.restaurants-menu-listing.visible-mssg {
  margin-top: 31px !important;
}
.new-menu-asap-time {
  border: 1px solid var(--btnColor) !important;
  padding: 3px 10px !important;
  background: var(--btnColor) !important;
  color: #ffffff !important;
  font-size: 14px !important;
}

.trash-icon {
  color: #f06f00;
}

.notification-para a {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}

.braintree-sheet__content--form
  .braintree-form__field-group
  .braintree-form__field.braintree-form__checkbox {
  margin: 0px !important;
}
/* .braintree-form__field-group {
  width: 100%!important;
} */
.braintree-form__label {
  width: 167px !important;
}

.braintree-sheet__content--form
  .braintree-form__field-group
  .braintree-form__field.braintree-form__checkbox
  input {
  margin-top: 5px !important;
}
.pwa-download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  /* bottom: 30px; */
  bottom: 20px;
  left: 20px;
  background-color: #f06f00;
  /* background-color:#2B2D36 ; */
  /* width: 50px; */
  height: 50px;
  text-decoration: none;
  border-radius: 35px;
  transition: all 0.3s ease;
  color: #fff;
  z-index: 9999;
  cursor: pointer;
  /* border: 1px solid #2B2D36; */
  border: 1px solid #fff;
  padding: 10px 10px;
}
.link-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: #fff;
  font-weight: 700;
}

/* .MuiButtonBase-root .MuiTab-root .MuiTab-textColorPrimary .Mui-selected .css-1alu2zn-MuiButtonBase-root-MuiTab-root{

} */
.css-1rtc2d3-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: #ffe4cd !important;
  color: #000 !important;
}

/* David Demo */
.css-jpln7h-MuiTabs-scroller {
  overflow-x: auto !important;
}

/* width */
.css-jpln7h-MuiTabs-scroller::-webkit-scrollbar {
  height: 2px !important;
}

/* Track */
.css-jpln7h-MuiTabs-scroller::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
.css-jpln7h-MuiTabs-scroller::-webkit-scrollbar-thumb {
  background: transparent !important;
}

/* Handle on hover */
.css-jpln7h-MuiTabs-scroller::-webkit-scrollbar-thumb:hover {
  background: transparent !important;
}
/* David Demo */

.css-1anid1y {
  overflow: scroll !important;
}
/* width */
.css-1anid1y::-webkit-scrollbar {
  height: 2px !important;
}

/* Track */
.css-1anid1y::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
.css-1anid1y::-webkit-scrollbar-thumb {
  background: transparent !important;
}

/* Handle on hover */
.css-1anid1y::-webkit-scrollbar-thumb:hover {
  background: transparent !important;
}

.css-9l3uo3 {
  font-size: 14px !important;
}
.css-ahj2mt-MuiTypography-root {
  font-size: 15px !important;
}

.cuisines-arrow {
  border: 1px solid #837d7d;
  border-radius: 50%;
  color: #837d7d;
  padding: 1px;
  cursor: pointer;
}

.time-button {
  background: var(--btnColor);
  color: #fff;
  border: 1px solid var(--btnColor);
  padding: 10px;
  font-weight: 700;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.search-sty {
  font-weight: 400;
  /* color:var(--btnColor); */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.clear-search {
  cursor: pointer;
  color: var(--btnColor);
}

.search-menu-img-dev {
  height: 30px;
  width: 30px;

  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 2px;
  object-fit: cover;
  /* padding: 0px; */
}

.smash-data-mobile .pro_im {
  margin-right: 9px;
  min-width: 30px;
}

.smash-data-mobile .pro_im svg {
  margin-right: 0px;
  margin: 0 auto;
  display: block;
}

.dev-choose-address {
  z-index: 10 !important;
}

.pwa-download-btn {
  z-index: 20 !important;
}

/* .splace_screen {
  padding: 20px;
  text-align: center;
  position: fixed;
  background: #fff;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.css-yhdjxa {
  min-width: none !important;
  max-width: none !important;
}
.css-1rtc2d3-MuiButtonBase-root-MuiTab-root {
  min-width: none !important;
  max-width: none !important;
}
