
@media(max-width:1400px)
{
    .search-reasturants .form-control {
        height: 46px;
    }
    .timeandaddress {
        margin-left: 10px;
    }

    .search-login-wrapper {
        width: 362px;
    }

.main-logo img{
    width: 101px;
}


.header-wrapper {
    flex: 70% 1;
    max-width: 70%;
}

.search-reasturants form {
    margin: 0px 6px;
}

.timeandaddress .time-btn:first-child {
    max-width: 232px;
}
.timeandaddress .time-btn {
    font-size: 14px;
    padding: 0px 8px;
}
    .header-section .nav-tabs a {
        margin-right: 0px;
        font-size: 16px;
        padding: 7px 14px;
    }
}

@media(max-width:1199px)
{
    /* .timeandaddress .to {
        padding: 0px;
    } */

}

@media(max-width:991px)
{
    .main-logo {
        max-width: 147px;
    }
    .timeandaddress {
        position: absolute;
        top: 72px;
        margin-left: 0px;
        border-top: 1px solid #0000001f;
        border-bottom: 1px solid #0000001f;
        justify-content: center;
        padding: 15px 0px;
        width: 100%;
        left: 0;
        right: 0;
        background: #fff;
        border-left: none;
    }
    .search-reasturants form {
        margin: 0px 9px;
    }
    .search-reasturants .form-control {
        height: 40px;
    }
    .timeandaddress .time-btn:first-child {
        max-width: initial;
    }
    .header-wrapper {
        flex: 49% 1;
        max-width: 49%;
    }
    .picktime-mobile-picktime {
        top: 43px;
    }

    .timeandaddress svg {
        font-size: 1.3em;
    }
    .timeandaddress .time-btn{font-weight:600;}
    .timeandaddress_color {
        color: var(--btnColor);
    }

}


@media(max-width:767.98px)
{
    .header-wrapper {
        flex: 1;
        max-width: 100%;
    }

    .search-reasturant-listing ul {
        max-height: 100%;
        overflow-y: auto;
        height: calc(100vh - 84px);
    }

    .search-reasturants form {
        margin: 0px 0px;
    }
    .search-login-wrapper {
        width: auto;
        /* display: none!important; */
    }
    .header-cart-notification .rest-header {
        margin-top: -31px;
    }
    .header-cart-notification .header-notificatin-wrapper {
        top: 0!important;
        left: 0;
        right: 0;
        width: 100%!important;
        position: fixed!important;
        transform: translate(0);
        bottom: 0;
        border: none;
        padding: 25px 14px;
    }
    .timeandaddress {
        top: 60px;
    }
    .picktime-mobile-picktime {
        left: 50%;
        transform: translatex(-50%);
    }

    .selection-tabs {
        margin-right: 4px;
        font-size: 14px;
        padding: 5px 10px;
    }
    .search-reasturants .search-icon {
        right: 19px;
    }
    .location-icon-header{
        width: 10px;
    }
  

    .modal-footer-dp {
        align-items: center;
        background-color: var(--bs-modal-footer-bg);
        border-bottom-left-radius: var(--bs-modal-inner-border-radius);
        border-bottom-right-radius: var(--bs-modal-inner-border-radius);
        border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        justify-content: flex-end;
    }


/* search-mobile */

.search-rest-mobile {
    position: fixed!important;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    bottom: 0;
    right: 0;
    top: 0;
    background: #fff;
    display: none;
}

.search-reasturants.show-search{
    display: block;
}
.search-reasturants .form-control {
    border: none;
    border-radius: 9px;
}
.search-reasturants .form-control::placeholder{
    font-size: 14px;
}

.mobile_search_ss {
    background: transparent;
    padding: 17px 10px;
    margin: 0px 0px;
}
.search-reasturant-listing b {
    padding-top: 15px;
}
.search-reasturant-listing {
    border: none;
    box-shadow: none;
    max-height: 900px;
    width: 100%;
    padding-top: 90px;
    bottom: 0px;
}
/* search-mobile */
.front-header .nav-tabs {
    position: absolute;
    right: 16px;
}

.picktime-mobile-picktime.address_picker_main {
    width: 100%;
    height: 100vh;
    margin-top: 0;
    top: 0;
    position: fixed;
}

}
@media(max-width:575.98px)
{

    .modal-bottom-actions-dp {
        background-color: rgb(255, 255, 255);
        bottom: 0px;
        box-shadow: rgba(0, 0, 0, 0.22) 0px -2px 7px 0px;
        left: 0px;
        margin: 0px;
        position: fixed;
        right: 0px;
        padding: 17px 15px 17px !important;
    }
    .timeandaddress {
        top: 56px;
        padding: 10px 0;
        background-color: #fff;
        border-bottom: 1px solid #0000001f;
    }

    .main-logo button {
        width: 27px;
    }

    /* .back-btn.rest-debit-wrapper {
        top: -50px!important;
        z-index: 9999;
        position: absolute;
    } */

    .timeandaddress .time-btn {
        font-size: 14px;
        padding: 2px 0px;
    }


    .header-cart {
        padding: 6px 12px;
    }
    .header-notificatin-wrapper h4{
        font-size: 21px;
    }
    .header-login-btn span {
        font-size: 14px;
    }
    .header-login-btn .header-btn1{
        position:initial;
    }
    .header-notificatin-wrapper ul li {
        padding: 11px 0;
    }
    .header-notificatin-wrapper {
        top: 57px!important;
        width: 94%!important;
        margin: 0 auto;
        display: block;
        padding: 12px 19px;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: none!important;
        border: 1px solid #00000042;
        border-top: none;
    }


    .picktime-mobile-picktime h3 {
        font-size: 18px;
    }
    .header-section .nav-tabs a {
        font-size: 14px;
        padding: 6px 10px;
    }
    /* .main-logo {
        max-width: 107px;
    } */

    .main-logo a img {
        max-height: 32px;
    }

    .main-logo a {
        margin-left: 5px;
    }

    .picktime-mobile-picktime {
        width: calc(93% - 16px);
        padding: 12px 19px 17px;
    }
    /* .search-login-wrapper {
        max-width: 15%;
    } */


    /* .picktime-mobile-picktime.address_picker_main {
        width: 100%;
        height: calc(100vh - 132px);
        margin-top: 30px;
    } */

    /* .address_picker_main .addressLists {
        max-height: initial;
        height: calc(100vh - 288px);
        overflow-y: auto;
    } */


    .address_picker_main .addressLists {
        max-height: initial;
        height: calc(100vh - 191px);
        overflow-y: auto;
    }

    .address_picker_main .autocomplete-dropdown-container{
        max-height: initial;
        height: calc(100vh - 288px);
        overflow-y: auto;}


}


@media(max-width:375px){
    .timeandaddress .time-btn {
        font-size: 12px;
        padding:5px;
    }
    .timeandaddress{padding:7px 0;}
    .main-logo a{margin-left:0;}

}