.header-notificatin-wrapper .shopping-cart-items {
  height: 100vh;
  max-height: 300px;
  overflow-y: scroll !important;
}

@media (max-width: 575px) {
  .header-notificatin-wrapper .shopping-cart-items ul {
    max-height: 236px !important;
  }
}
