@media (max-width: 991.98px) {
  .main-footer {
    padding: 35px 0 35px;
  }
}

@media (max-width: 575px) {
  .footer-menu {
    margin-bottom: 25px;
  }
  .main-footer {
    padding: 28px 0 14px;
  }
  .top-scroller {
    bottom: 30px !important;
  }
  .pwa-download-btn {
    /* bottom: 100px  !important; */
    left: 0px !important;
    /* top: 57px; */
    border-radius: 0px;
    right: 0;
    justify-content: space-between;
    border: none;
    bottom: "15px";
  }
}
