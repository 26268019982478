.order-detail-left {
  height: 100vh;
  padding-right: 0;
}
.order-detail-right {
  background-color: #0000009e;
  height: 100vh;
  padding-right: 0;
  padding-left: 0;
}
.order-head {
  border-bottom: 1px solid #dadada;
}
.order-head img {
  width: 89px;
  margin-left: 20px;
  padding: 15px;
  border-radius: 6px;
}
.oder-contant {
  margin: 40px 40px;
}
.borders {
  border-bottom: 7px solid #0062ff;
  border-radius: 5px;
  margin-bottom: 50px;
}
.oder-contant p {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  display: flex;
}
.oder-contant p img {
  height: 18px;
  align-self: center;
  margin-right: 5px;
}
.red-badge {
  color: red !important;
  background-color: rgb(224, 183, 183) !important;
}
.badge-one {
  margin: auto;
  font-size: 20px;
  font-weight: 600;
  background-color: #eafaf4;
  color: #3dd598;
  padding: 10px 40px;
  width: 180px;
  margin-top: 30px;
  margin-bottom: 40px;
  border-radius: 5px;
}
.order-lebal {
  font-size: 16px !important;
  color: #92929d;
  text-align: start !important;
  margin-bottom: 3px;
}
.order-text {
  font-size: 17px !important;
  color: #000;
  font-weight: 500 !important;
  text-align: start !important;
  margin-bottom: 20px;
}
.phone-img {
  width: 55px;
  background-color: #f7f5ff;
  border-radius: 14px;
  padding: 10px;
  position: absolute;
  top: 10px;
  right: 0;
}
/* .order-detail-right {
  height: 100vh;
}
.order-detail-right div {
  height: 100vh;
} */
.address-icon {
  display: flex;
  width: 40px;
  align-self: center;
  /* background-color: #e8f9ee; */
  justify-content: center;
  margin-right: 10px;
}
.address-box {
  height: 55px;
}
.address-icon img {
  background-color: #e8f9ee;
  padding: 6px;
  border-radius: 18px;
}
.dot-line {
  color: #92929d;
  background-image: repeating-linear-gradient(
    0deg,
    #92929d,
    #92929d 5px,
    transparent 0,
    transparent 8px
  );
  width: 1px;
  position: absolute;
  bottom: 50px;
  height: 45px;
  left: 19px;
  z-index: -1;
}
.map-box {
  width: 100% !important;
  height: 100vh !important;
}
.ol-control {
  background-color: transparent !important;
}
.widget-visible {
  display: block !important;
}

.custom-driver-box {
  height: 46px;
  width: 46px;
  background-color: #f7f5ff;
  border-radius: 14px;
}
.driver-font {
  font-size: 17px !important;
}

.driver-info {
  /* padding: 2rem 0px !important; */
  padding: 0px 0px !important;
  padding-top: 0px !important ;
}
@media only screen and (max-width: 1296px) {
  .address-box {
    height: 84px;
  }
  .dot-line {
    color: #92929d;
    background-image: repeating-linear-gradient(
      0deg,
      #92929d,
      #92929d 5px,
      transparent 0,
      transparent 8px
    );
    width: 1px;
    position: absolute;
    bottom: 80px;
    height: 88px;
    left: 18px;
    z-index: -1;
  }
}
@media only screen and (max-width: 912px) {
  .dot-line {
    color: #92929d;
    background-image: repeating-linear-gradient(
      0deg,
      #92929d,
      #92929d 5px,
      transparent 0,
      transparent 8px
    );
    width: 1px;
    position: absolute;
    bottom: 70px;
    height: 66px;
    left: 11px;
    z-index: -1;
  }
}
@media only screen and (max-width: 872px) {
  .address-box {
    height: 108px;
  }
  .dot-line {
    color: #92929d;
    background-image: repeating-linear-gradient(
      0deg,
      #92929d,
      #92929d 5px,
      transparent 0,
      transparent 8px
    );
    width: 1px;
    position: absolute;
    bottom: 80px;
    height: 88px;
    left: 10px;
    z-index: -1;
  }
}
@media only screen and (max-width: 767px) {
  .order {
    flex-direction: column-reverse;
  }
  .order-detail-left {
    height: 50vh !important;
  }
  .map-box {
    height: 50vh !important;
  }
  .order-detail-right {
    height: 50vh !important;
  }
}

@media only screen and (max-width: 767px) {
  .address-box {
    height: 75px;
  }
  .dot-line {
    height: 66px;
    left: 18px;
  }
}
