.delete-case-modal h6 {
    font-family: 'Inter';
    font-size: 21px;
    font-weight: 800;
}

.delete-case-modal svg {
    font-size: 29px;
}

.delete-case-modal p {
    font-family: 'Inter';
    font-size: 19px;
}

@media (max-width: 575px) {
    .delete-case-modal h6 {
        font-size: 16px;
    }

    .delete-case-modal p {
        font-size: 15px;
    }
}
.delete-case-modal h6 {
    font-family: 'Inter' !important;
    font-size: 21px !important;
    font-weight: 800 !important;
}

.delete-case-modal svg {
    font-size: 29px !important;
}

.delete-case-modal p {
    font-family: 'Inter' !important;
    font-size: 19px !important;
}

@media (max-width: 575px) {
    .delete-case-modal h6 {
        font-size: 16px !important;
    }

    .delete-case-modal p {
        font-size: 15px !important;
    }
}